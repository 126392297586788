import { useMutation } from '@tanstack/react-query';

import { useApiSdk } from 'ui-core';

import { useInvalidateRtoOrderData } from './use-rto-order-form-data';
import type { SetRtoFieldsMutation } from 'api-client/types';

export function useSetRtoFields() {
    const sdk = useApiSdk();
    const invalidate = useInvalidateRtoOrderData();

    return useMutation<SetRtoFieldsMutation['setRtoFields'], Error, any>({
        mutationFn: async (vars) => {
            if (!vars.orderId) {
                return;
            }

            const result = await sdk.SetRtoFields({
                input: {
                    ...vars,
                },
            });
            const order = result.setRtoFields;

            return order;
        },
        onSuccess: (response) => {
            if (response) {
                invalidate(response.id);
            }
        },
    });
}
