import { useNavigation } from '@pankod/refine-core';
import { useTable, flexRender } from "@pankod/refine-react-table";
import {
    Box,
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    Text,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    NumberField,
    Select,
} from "@pankod/refine-chakra-ui";

import {
    ColumnFilter,
    ColumnSorter,
    FilterElementProps,
    MainSection,
    Pagination,
    useCurrentAdmin,
    getStatusString,
} from 'ui-core';
import { Branding } from 'ui-rto';

import ActionsCard from './actions-card';

export const AdminDashboard = () => {
    const adminQuery = useCurrentAdmin();
    const admin = adminQuery.data?.data;

    const navMethods = useNavigation();

    const columns = [
        {
            id: 'customer',
            header: 'Customer',
            accessorKey: 'customer',
            
            cell: function render({ getValue }: any) {
                let customer = getValue();
                return customer ? `${customer.firstName} ${customer.lastName}` : '';
            },
            meta: {
                filterOperator: "contains",
            },
        },
        {
            id: 'total',
            header: 'total',
            accessorKey: 'total',
            
            
            cell: function render({ getValue }: any) {
                return (
                    <NumberField options={{ style: 'currency', currency: 'USD' }} value={getValue()/100} />
                );
            },
        },
        {
            id: 'state',
            header: 'Status',
            accessorKey: 'state',
            cell: function render({ getValue }: any) {
                return (
                    getStatusString(getValue() )
                );
            },
            meta: {
                filterElement: function render(props: FilterElementProps) {
                    return (
                        <Select defaultValue="ArrangingPayment" {...props}>
                            <option value="Draft">Draft</option>
                            <option value="PendingApproval">Pending</option>
                            <option value="Approved">Accepted</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Processed">Processed</option>
                            <option value="Cancelled">Cancelled</option>
                        </Select>
                    );
                },
                filterOperator: "eq",
            }
        },
        {
            id: 'organizationName',
            header: 'Company',
            accessorKey: 'customFields.organization',
            cell: ({ getValue }: any) => {
                return getValue()?.name || '';
            },
            enableSorting: false,
            enableColumnFilter: false,
        },
    
    ];

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            tableQueryResult: { data: tableData },
            setCurrent,
            pageCount,
            current,
        },
    } = useTable({ 
        columns,
        refineCoreProps: {
            resource: 'orders',
            initialSorter: [
                {
                    field: "id",
                    order: "desc",
                    
                },
            ],
            permanentFilter: [
                //https://refine.dev/docs/advanced-tutorials/data-provider/handling-filters/#combining-filters
                {
                    operator: "or",
                    value: [
                        {
                            field: "state",
                            operator: "in",
                            value: ["PendingApproval", "Delivered", "PendingCancellation"],
                        },
                    ],
                },
            ],
            
        },
    });



    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
            
        },
    }));

    let totalPending = 0;
    let totalPendingCount = 0;
    tableData?.data.map((item) => {
        if(item.state ==="PendingApproval") {
            totalPendingCount+=1;
            totalPending += item.total as number;


        }
    });

    function doRowNav(id: any){
        if(tableData?.data[id].state === "Draft") {
            navMethods.create("orders");
        } else {
            navMethods.show("orders", tableData?.data[id].id as any);
        }
    }

    return (
        <MainSection variant="form">
            <List resource="orders" title={"Hello " + admin?.firstName + " " + admin?.lastName} breadcrumb="" canCreate={false}>
                <StatGroup pb={4}>
                    <Stat>
                        <StatLabel textAlign="center">Pending Contract Value</StatLabel>
                        <StatNumber textAlign="center">
                            <NumberField options={{ style: 'currency', currency: 'USD' }} value={totalPending/100} />
                        </StatNumber>
                        
                    </Stat>

                    <Stat>
                        <StatLabel textAlign="center">Pending Contracts</StatLabel>
                        <StatNumber textAlign="center">{totalPendingCount}</StatNumber>
                        
                    </Stat>
                </StatGroup> 
                <TableContainer whiteSpace="pre-line">
                    <Table variant="simple" size="sm" colorScheme="gray">
                        <Thead>
                            {getHeaderGroups().map((headerGroup) => (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <Th key={header.id}>
                                            {!header.isPlaceholder && (
                                                <HStack spacing="2">
                                                    <Text>
                                                        {flexRender(
                                                            header.column.columnDef
                                                                .header,
                                                            header.getContext(),
                                                        )}
                                                    </Text>
                                                    <HStack spacing="2">
                                                        <ColumnSorter
                                                            column={header.column}
                                                        />
                                                        <ColumnFilter
                                                            column={header.column}
                                                        />
                                                    </HStack>
                                                </HStack>
                                            )}
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody>
                            {getRowModel().rows.map((row) => (
                                <Tr cursor="pointer" key={row.id} onClick={() => doRowNav(row.id as any)}>
                                    {row.getVisibleCells().map((cell) => (
                                        <Td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </Td>
                                    ))}
                                    
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Pagination
                    current={current}
                    pageCount={pageCount}
                    setCurrent={setCurrent}
                />
            </List>
            <Box mt={8}>
                <ActionsCard />
            </Box>
        </MainSection>
    
    );
};

export default AdminDashboard;
