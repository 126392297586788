import {
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Select,
    Skeleton,
    Stack,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useForm, useFieldArray } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNotification } from '@pankod/refine-core';

import { MainSection, RhfMoneyInput, useApiSdk } from 'ui-core';
import { EmailNotificationSettings } from 'ui-rto';
import { UpdateGlobalSettingsInput } from 'api-client/types';

type UpdateSettingsInput = {
    global: UpdateGlobalSettingsInput;
    payment: any;
};

const PageSkeleton = () => {
    return (
        <MainSection variant="form">
            <Box p={8}>
                <Stack>
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                </Stack>
            </Box>
        </MainSection>
    );
};

const PaymentMethodSettings = (props: any) => {
    const { fields } = useFieldArray({
        control: props.form.control,
        name: 'payment_methods',
    });

    return (
        <Card bg="white">
            <CardBody>
                <Flex alignItems="center" mb={3}>
                    <Heading size="md">Payment Methods</Heading>
                </Flex>
                {fields.map((fieldData: any, index) => {
                    if (fieldData.code == 'clearent-credit-card') {
                        return (
                            <Box key={fieldData.id}>
                                <Box fontSize="md" mb={4}>
                                    {fieldData.name}
                                </Box>
                                <Stack spacing={4}>
                                    <FormControl>
                                        <FormLabel>API Key</FormLabel>
                                        <Input
                                            {...props.form.register(
                                                `payment_methods.${index}.apiKey`
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Publishable Key</FormLabel>
                                        <Input
                                            {...props.form.register(
                                                `payment_methods.${index}.publishableKey`
                                            )}
                                        />
                                    </FormControl>
                                    <Box>
                                        <Checkbox
                                            {...props.form.register(
                                                `payment_methods.${index}.testMode`
                                            )}
                                        >
                                            Enable test mode
                                        </Checkbox>
                                    </Box>
                                </Stack>
                            </Box>
                        );
                    }
                    return null;
                })}
            </CardBody>
        </Card>
    );
};

async function getPaymentMethodSettings(sdk: ReturnType<typeof useApiSdk>) {
    const paymentMethodsResult = await sdk.GetPaymentMethodList({ options: {} });
    const paymentMethodSettings: any[] = [];
    const hasSettings = ['clearent-credit-card'];

    if (paymentMethodsResult.paymentMethods.totalItems) {
        for (let paymentMethod of paymentMethodsResult.paymentMethods.items) {
            if (hasSettings.indexOf(paymentMethod.handler.code) >= 0) {
                const settings: Record<string, string | boolean> = {
                    methodId: paymentMethod.id,
                    name: paymentMethod.name,
                    code: paymentMethod.handler.code,
                };

                for (let args of paymentMethod.handler.args) {
                    let value = args.value === 'true' ? true : (args.value === 'false' ? false : args.value);
                    settings[args.name] = value;
                }

                paymentMethodSettings.push(settings);
            }
        }
    }

    return paymentMethodSettings;
}

async function updatePaymentMethodSettings(
    currentData: any,
    newData: any,
    sdk: ReturnType<typeof useApiSdk>
) {
    const nonArgumentKeys = ['id', 'methodId', 'name', 'code'];

    for (let i = 0; i < currentData.length; i++) {
        let paymentMethodSettings = currentData[i];
        let fieldData: any[] = [];

        for (let key in newData[i]) {
            if (nonArgumentKeys.indexOf(key) < 0) {
                fieldData.push({ name: key, value: newData[i][key].toString() });
            }
        }

        await sdk.UpdatePaymentMethod({
            input: {
                id: paymentMethodSettings.methodId,
                handler: {
                    code: paymentMethodSettings.code,
                    arguments: fieldData,
                },
            },
        });
    }
}

export const SettingsPage: React.FC = () => {
    const notification = useNotification();
    const queryClient = useQueryClient();
    const sdk = useApiSdk();

    const form = useForm();
    const register = form.register;

    const settingsQuery = useQuery({
        queryKey: ['globalSettings'],
        queryFn: async () => {
            const result = await sdk.GetGlobalSettings();

            const paymentMethods = await getPaymentMethodSettings(sdk);

            if (result.globalSettings) {
                const defaultLdw = result.globalSettings.customFields?.contractDefaultLDW || '';
                const defaultLdwObject = defaultLdw ? JSON.parse(defaultLdw) : {};
                const notificationSettingsJson = result.globalSettings.customFields?.notificationSettings || '';
                const notificationSettings = notificationSettingsJson ? JSON.parse(notificationSettingsJson) : [];

                form.reset({
                    client_id: result.globalSettings.customFields?.signNowClientId || '',
                    auth_token: result.globalSettings.customFields?.signNowAuthToken || '',
                    ziptax_key: result.globalSettings.customFields?.ziptaxKey || '',
                    processing_fee: result.globalSettings.customFields?.contractProcessingFee || 0,
                    default_ldw: defaultLdwObject.value || 0,
                    default_ldw_type: defaultLdwObject.type || 'fixed',
                    payment_methods: paymentMethods,
                    notification_settings: notificationSettings,
                });
            }

            const rtoConfig = await sdk.GetRtoConfig();

            return {
                ...result.globalSettings,
                signNowConnectionUrl: rtoConfig.rtoConfig?.signNowConnectionUrl,
                paymentMethods,
            };
        },
    });

    const updateSettingsMutation = useMutation<any, any, UpdateSettingsInput>({
        mutationFn: async (variables) => {
            await sdk.UpdateGlobalSettings({
                input: variables.global,
            });
            await updatePaymentMethodSettings(
                settingsQuery.data?.paymentMethods,
                variables.payment,
                sdk
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['globalSettings']);

            if (notification.open) {
                notification.open({
                    type: 'success',
                    message: 'Settings saved.',
                });
            }
        },
    });

    const handleClickSave = () => {
        form.handleSubmit((formData) => {
            updateSettingsMutation.mutate({
                global: {
                    customFields: {
                        signNowClientId: formData.client_id,
                        signNowAuthToken: formData.auth_token,
                        ziptaxKey: formData.ziptax_key,
                        contractProcessingFee: formData.processing_fee,
                        contractDefaultLDW: JSON.stringify({
                            value: formData.default_ldw,
                            type: formData.default_ldw_type,
                        }),
                        notificationSettings: JSON.stringify(formData.notification_settings),
                    },
                },
                payment: formData.payment_methods,
            });
        })();
    };

    const handleClickConnect = () => {
        const url = settingsQuery.data?.signNowConnectionUrl;
        if (url) {
            window.location = url;
            return;
        }
    };

    if (settingsQuery.isLoading && !settingsQuery.data) {
        return <PageSkeleton />;
    }

    return (
        <MainSection variant="form">
            <Stack spacing={8}>
                <Card bg="white">
                    <CardBody>
                        <Flex justifyContent="space-between">
                            <Heading size="lg">Settings</Heading>
                            <Button
                                onClick={handleClickSave}
                                isLoading={updateSettingsMutation.isLoading}
                            >
                                Save
                            </Button>
                        </Flex>
                    </CardBody>
                </Card>
                <Card bg="white">
                    <CardBody>
                        <Flex alignItems="center" mb={3}>
                            <Heading size="md">Global Contract Settings</Heading>
                        </Flex>
                        <Stack spacing={4}>
                            <Flex alignItems="center">
                                <FormControl flex="1">
                                    <FormLabel>Processing Fee</FormLabel>
                                    <RhfMoneyInput name="processing_fee" control={form.control} />
                                </FormControl>
                                <Box flex="1" />
                            </Flex>
                            <Flex alignItems="center">
                                <FormControl flex="1">
                                    <FormLabel>Default LDW</FormLabel>
                                    <Flex alignItems="center" flex="1">
                                        <Select {...register('default_ldw_type')} width="100px">
                                            <option value="fixed">$</option>
                                            <option value="percentage">%</option>
                                        </Select>
                                        <Input type="number" {...register('default_ldw')} />
                                    </Flex>
                                </FormControl>
                                <Box flex="1" />
                            </Flex>
                        </Stack>
                    </CardBody>
                </Card>
                <EmailNotificationSettings
                    form={form}
                    initialData={settingsQuery.data}
                />
                <PaymentMethodSettings
                    form={form}
                    initialData={settingsQuery.data?.paymentMethods}
                />
                <Card bg="white">
                    <CardBody>
                        <Flex alignItems="center" mb={3}>
                            <Heading size="md">SignNow Integration</Heading>
                            {settingsQuery.data?.customFields?.signNowAccessToken && (
                                <Box ml={4}>
                                    <Badge variant="outline">Connected</Badge>
                                </Box>
                            )}
                        </Flex>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Client ID</FormLabel>
                                <Input {...register('client_id')} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Authentication Token</FormLabel>
                                <Input {...register('auth_token')} />
                            </FormControl>
                            <Box>
                                <Button
                                    leftIcon={<ExternalLinkIcon />}
                                    onClick={handleClickConnect}
                                    disabled={!settingsQuery.data?.signNowConnectionUrl}
                                >
                                    Connect SignNow
                                </Button>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>
                <Card bg="white">
                    <CardBody>
                        <Flex alignItems="center" mb={3}>
                            <Heading size="md">ZipTax Integration</Heading>
                        </Flex>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>API Key</FormLabel>
                                <Input {...register('ziptax_key')} />
                            </FormControl>
                        </Stack>
                    </CardBody>
                </Card>
            </Stack>
        </MainSection>
    );
};
