import { useMemo } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Heading,
    Input,
    Radio,
    RadioGroup,
    Select,
    Stack,
    HStack,
    VStack,
} from '@chakra-ui/react';

import { ActionBar, RhfPhoneInput, RhfSsnInput, useApiSdk, US_STATES } from 'ui-core';

import {
    useRtoOrderFormData,
    useInvalidateRtoOrderData,
} from '../../../hooks/use-rto-order-form-data';
import { PhotoIdUpload } from '../photo-id-upload';

type AddressFormProps = {
    title?: string;
    type: 'billing' | 'shipping';
    form: UseFormReturn<any>;
};

const AddressForm = (props: AddressFormProps) => {
    const register = props.form.register;
    const addressType = props.type;
    const errors = props.form.formState.errors;

    return (
        <VStack alignItems="start" width="100%" spacing={4}>
            {props.title && (
                <Box>
                    <Heading size="md">{props.title}</Heading>
                </Box>
            )}
            <FormControl isInvalid={!!errors[addressType + '_street1']}>
                <FormLabel>Street address</FormLabel>
                <Input
                    as={Input}
                    {...register(addressType + '_street1', { required: 'Required.' })}
                />
                <FormErrorMessage>
                    {(errors[addressType + '_street1']?.message || '').toString()}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors[addressType + '_street2']}>
                <FormLabel>Apartment, suite, etc.</FormLabel>
                <Input as={Input} {...register(addressType + '_street2')} />
                <FormErrorMessage>
                    {(errors[addressType + '_street2']?.message || '').toString()}
                </FormErrorMessage>
            </FormControl>
            <HStack width="100%" alignItems="top">
                <FormControl flex="1" isInvalid={!!errors[addressType + '_city']}>
                    <FormLabel>City</FormLabel>
                    <Input {...register(addressType + '_city', { required: 'Required.' })} />
                    <FormErrorMessage>
                        {(errors[addressType + '_city']?.message || '').toString()}
                    </FormErrorMessage>
                </FormControl>
                <FormControl flex="1" isInvalid={!!errors[addressType + '_state']}>
                    <FormLabel>State</FormLabel>
                    <Select {...register(addressType + '_state', { required: 'Required.' })}>
                        <option value=""></option>
                        {Object.entries(US_STATES).map(([code, name]) => (
                            <option key={code} value={code}>
                                {name}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>
                        {(errors[addressType + '_state']?.message || '').toString()}
                    </FormErrorMessage>
                </FormControl>
                <FormControl width="8rem" isInvalid={!!errors[addressType + '_zip']}>
                    <FormLabel>Zip</FormLabel>
                    <Input {...register(addressType + '_zip', { required: 'Required.' })} />
                    <FormErrorMessage>
                        {(errors[addressType + '_zip']?.message || '').toString()}
                    </FormErrorMessage>
                </FormControl>
            </HStack>
        </VStack>
    );
};

const DriversLicenseForm = (props: any) => {
    const method = props.form.watch('driver_license_method');
    const uploaded = props.form.watch('driver_license_uploaded');
    const licenseNumber = props.form.watch('drivers_license');
    const fieldsConfig = props.fieldsConfig || {};
    const register = props.form.register;
    const errors = props.form.formState.errors;

    return (
        <Controller
            name="driver_license_uploaded"
            control={props.form.control}
            render={({ field, fieldState }) => {
                const isUploaded = field.value;
                const validationError = fieldState.error;

                const handleChange = (doc: any) => {
                    field.onChange(Boolean(doc));
                };

                return (
                    <Box width="100%">
                        <Heading size="md" mb={4} display="flex" alignItems="center">
                            Driver License
                            {validationError?.message && (
                                <Box color="red.500" fontSize="sm" ml={4}>
                                    {validationError?.message}
                                </Box>
                            )}
                        </Heading>
                        {fieldsConfig.drivers_license && (
                            <>
                                <HStack width="100%" alignItems="top" mb={8}>
                                    <FormControl isInvalid={!!errors.drivers_license} flex="1">
                                        <FormLabel>License Number</FormLabel>
                                        <Input
                                            {...register('drivers_license', {
                                                validate: (value: string) => {
                                                    if (
                                                        value ||
                                                        uploaded ||
                                                        method !== 'upload_now'
                                                    ) {
                                                        return true;
                                                    }
                                                    return 'Required.';
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    {fieldsConfig.dob ? (
                                        <FormControl isInvalid={!!errors.dob} flex="1">
                                            <FormLabel>Date of Birth</FormLabel>
                                            <Input
                                                type="date"
                                                {...register('dob', {
                                                    validate: (value: string) => {
                                                        if (
                                                            value ||
                                                            uploaded ||
                                                            method !== 'upload_now'
                                                        ) {
                                                            return true;
                                                        }
                                                        return 'Required.';
                                                    },
                                                })}
                                            />
                                        </FormControl>
                                    ) : (
                                        <Box flex="1"></Box>
                                    )}
                                </HStack>
                                <Box mb={2}>License Image</Box>
                            </>
                        )}
                        <Controller
                            name="driver_license_method"
                            control={props.form.control}
                            render={({ field: methodField }) => {
                                return !isUploaded ? (
                                    <RadioGroup {...methodField} mb={4}>
                                        <Stack direction="row">
                                            <Radio value="upload_now">Upload now</Radio>
                                            <Radio value="upload_later">Upload later</Radio>
                                            <Radio value="customer_upload">
                                                Customer upload when signing
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                ) : (
                                    <></>
                                );
                            }}
                        />
                        {method === 'upload_now' && (
                            <PhotoIdUpload order={props.order} onChange={handleChange} />
                        )}
                    </Box>
                );
            }}
            rules={{
                validate: (value) => {
                    if (licenseNumber || value || method !== 'upload_now') {
                        return true;
                    }
                    return 'Required.';
                },
            }}
        />
    );
};

export const StepCustomer = () => {
    const sdk = useApiSdk();

    const pageQuery = useRtoOrderFormData();
    const invalidate = useInvalidateRtoOrderData();

    const initialState: Record<string, any> = useMemo(() => {
        const order = pageQuery.data?.order;
        let formData: Record<string, any> = {
            first_name: order?.customer?.firstName || '',
            last_name: order?.customer?.lastName || '',
            email: order?.customer?.emailAddress || '',
            phone: order?.customer?.phoneNumber || '',
            ssn: order?.rtoContract?.fields?.ssn || '',
            dob: order?.rtoContract?.fields?.dob || '',
            drivers_license: order?.rtoContract?.fields?.drivers_license || '',
            shipping_street1: order?.shippingAddress?.streetLine1 || '',
            shipping_street2: order?.shippingAddress?.streetLine2 || '',
            shipping_city: order?.shippingAddress?.city || '',
            shipping_state: order?.shippingAddress?.province || '',
            shipping_zip: order?.shippingAddress?.postalCode || '',
            billing_street1: order?.billingAddress?.streetLine1 || '',
            billing_street2: order?.billingAddress?.streetLine2 || '',
            billing_city: order?.billingAddress?.city || '',
            billing_state: order?.billingAddress?.province || '',
            billing_zip: order?.billingAddress?.postalCode || '',
            billing_same_as: true,
            driver_license_method: order?.rtoContract?.driverLicenseMethod || 'upload_now',
            driver_license_uploaded: false,
        };

        const driversLicenseDoc = order?.rtoContract?.documents?.find(
            (doc: any) => doc.type === 'drivers-license'
        );
        if (driversLicenseDoc && driversLicenseDoc.status != 'none') {
            formData.driver_license_uploaded = true;
        }

        return formData;
    }, [pageQuery.data]);

    const form = useForm({ defaultValues: initialState });
    const register = form.register;
    const errors = form.formState.errors;

    const billingSameAsShipping = form.watch('billing_same_as');

    const onSubmit = async (formData: any) => {
        const order = pageQuery.data?.order;

        if (order) {
            const shippingAddress = {
                fullName: `${formData.first_name} ${formData.last_name}`,
                streetLine1: formData.shipping_street1,
                streetLine2: formData.shipping_street2,
                city: formData.shipping_city,
                province: formData.shipping_state,
                postalCode: formData.shipping_zip,
                countryCode: 'US',
            };

            let billingAddress = shippingAddress;

            if (!formData.billing_same_as) {
                billingAddress = {
                    fullName: `${formData.first_name} ${formData.last_name}`,
                    streetLine1: formData.billing_street1,
                    streetLine2: formData.billing_street2,
                    city: formData.billing_city,
                    province: formData.billing_state,
                    postalCode: formData.billing_zip,
                    countryCode: 'US',
                };
            }

            const result = await sdk.SetRtoCustomer({
                input: {
                    orderId: order.id,
                    customer: {
                        firstName: formData.first_name,
                        lastName: formData.last_name,
                        emailAddress: formData.email,
                        phoneNumber: formData.phone,
                    },
                    billingAddress,
                    shippingAddress,
                    customFields: {
                        driverLicenseMethod: formData.driver_license_method,
                    },
                    additionalFormFields: {
                        ssn: formData.ssn || '',
                        dob: formData.dob || '',
                        drivers_license: formData.drivers_license || '',
                    },
                },
            });

            invalidate(result.setRtoCustomer?.id);
        }
    };

    const handleClickContinue = async () => {
        return form.handleSubmit(onSubmit, () => {
            throw new Error('Please fill out all required fields.');
        })();
    };

    const formFieldsConfig = pageQuery.data?.rtoConfig?.options?.fields || {};

    return (
        <form>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <VStack alignItems="start" width="100%" spacing={4}>
                    <Box>
                        <Heading size="md">Contact information</Heading>
                    </Box>
                    <HStack width="100%" alignItems="top">
                        <FormControl flex="1" isInvalid={!!errors.first_name}>
                            <FormLabel>First name</FormLabel>
                            <Input {...register('first_name', { required: 'Required.' })} />
                            <FormErrorMessage>
                                {(errors.first_name?.message || '').toString()}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl flex="1" isInvalid={!!errors.last_name}>
                            <FormLabel>Last name</FormLabel>
                            <Input {...register('last_name', { required: 'Required.' })} />
                            <FormErrorMessage>
                                {(errors.last_name?.message || '').toString()}
                            </FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <FormControl isInvalid={!!errors.email}>
                        <FormLabel>Email address</FormLabel>
                        <Input {...register('email', { required: 'Required.' })} />
                        <FormErrorMessage>
                            {(errors.email?.message || '').toString()}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.phone}>
                        <FormLabel>Phone</FormLabel>
                        <RhfPhoneInput
                            name="phone"
                            control={form.control}
                            rules={{ required: 'Required.' }}
                        />
                        <FormErrorMessage>
                            {(errors.phone?.message || '').toString()}
                        </FormErrorMessage>
                    </FormControl>
                    {formFieldsConfig.ssn && (
                        <HStack width="100%" alignItems="top">
                            <FormControl isInvalid={!!errors.ssn} flex="1">
                                <FormLabel>SSN</FormLabel>
                                <RhfSsnInput
                                    name="ssn"
                                    control={form.control}
                                    rules={{ required: 'Required.' }}
                                />
                            </FormControl>
                            {formFieldsConfig.dob && !formFieldsConfig.drivers_license ? (
                                <FormControl isInvalid={!!errors.dob} flex="1">
                                    <FormLabel>Date of Birth</FormLabel>
                                    <Input
                                        {...register('dob', {
                                            required: 'Required.',
                                        })}
                                    />
                                </FormControl>
                            ) : (
                                <Box flex="1"></Box>
                            )}
                        </HStack>
                    )}
                </VStack>
                <AddressForm type="shipping" title="Delivery address" form={form} />
                <Box>
                    <Checkbox {...register('billing_same_as')}>
                        Use same address for billing
                    </Checkbox>
                </Box>
                {!billingSameAsShipping && (
                    <AddressForm type="billing" title="Billing address" form={form} />
                )}
                <DriversLicenseForm
                    order={pageQuery.data?.order}
                    form={form}
                    fieldsConfig={formFieldsConfig}
                />
                <ActionBar submitLabel="Continue" onSubmit={handleClickContinue} />
            </VStack>
        </form>
    );
};

export default StepCustomer;
