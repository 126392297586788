export const CONTRACT_TEMPLATE_FRAGMENT = /* GraphQL */ `
    fragment ContractTemplate on ContractTemplate {
        id
        createdAt
        updatedAt
        title
        states
        companies
        content
        variables
        settings
        contentFile {
            id
            name
            source
        }
        productTypes
    }
    
`;

export const PARTIAL_CONTRACT_TEMPLATE_FRAGMENT = /* GraphQL */ `
    fragment PartialContractTemplate on ContractTemplate {
        id
        createdAt
        updatedAt
        title
        states
        companies
    }
`;

export const GET_CONTRACT_TEMPLATES_LIST = /* GraphQL */ `
    query GetContractTemplates($options: ContractTemplateListOptions) {
        contractTemplates(options: $options) {
            items {
                ...PartialContractTemplate
            }
            totalItems
        }
    }
    ${PARTIAL_CONTRACT_TEMPLATE_FRAGMENT}
`;

export const GET_CONTRACT_TEMPLATE = /* GraphQL */ `
    query GetContractTemplate($id: ID!) {
        contractTemplate(id: $id) {
            ...ContractTemplate
        }
    }
    ${CONTRACT_TEMPLATE_FRAGMENT}
`;

export const CREATE_CONTRACT_TEMPLATE = /* GraphQL */ `
    mutation CreateContractTemplate($input: CreateContractTemplateInput!) {
        createContractTemplate(input: $input) {
            ...ContractTemplate
        }
    }
    ${CONTRACT_TEMPLATE_FRAGMENT}
`;

export const UPDATE_CONTRACTTEMPLATE = /* GraphQL */ `
    mutation UpdateContractTemplate($input: UpdateContractTemplateInput!) {
        updateContractTemplate(input: $input) {
            ...ContractTemplate
        }
    }
    ${CONTRACT_TEMPLATE_FRAGMENT}
`;

export const DELETE_CONTRACT_TEMPLATES = /* GraphQL */ `
    mutation DeleteContractTemplate($id: ID!) {
        deleteContractTemplate(id: $id) {
            result
            message
        }
    }
`;

export const CONTRACT_SETTINGS_FRAGMENT = /* GraphQL */ `
    fragment ContractSettings on ContractSettings {
        id
        plan
        terms {
            length
            divisor
            initialPayment {
                from
                to
                value
            }
            earlyPurchasePercentage
        }
        greaterPaymentOptions
        ldwPrice {
            type
            value {
                from
                to
                value
            }
        }
        fees
    }
`;

export const GET_CONTRACT_SETTINGS = /* GraphQL */ `
    query GetContractSettings($templateId: ID) {
        contractSettings(templateId: $templateId) {
            ...ContractSettings
        }
    }
    ${CONTRACT_SETTINGS_FRAGMENT}
`;

export const UPLOAD_CONTRACT_TEMPLATE_FILE = /* GraphQL */`
    mutation UploadContractTemplateFile($file: Upload!) {
        uploadContractTemplateFile(file: $file) {
            id
            name
            source
        }
    }
`;
