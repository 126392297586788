import { useMemo } from 'react';
import {
    Box,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Heading,
    Input,
    Radio,
    Select,
    Stack,
    HStack,
    VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { ActionBar, RhfMoneyInput, RhfRadioGroup, useApiSdk } from 'ui-core';

import {
    useRtoOrderFormData,
    useInvalidateRtoOrderData,
} from '../../../hooks/use-rto-order-form-data';

type AttributeOption = {
    key: string;
    value: string;
    valueId?: string;
};

export const StepOrderDetails = () => {
    const sdk = useApiSdk();
    const pageQuery = useRtoOrderFormData();
    const invalidate = useInvalidateRtoOrderData();

    const initialState: Record<string, string> = useMemo(() => {
        const order = pageQuery.data?.order;
        let formData: Record<string, string> = {
            product_type: 'Shed',
            retail_price: '',
            style: '',
            size: '',
            reference_id: '',
            siding_color: '',
            trim_color: '',
            roof_color: '',
            condition: 'New',
        };

        if (order) {
            const product = order.lines[0]?.productVariant;
            formData.retail_price = order.total ? (order.total / 100).toFixed(2) : '';

            if (product) {
                Object.keys(formData).forEach((key) => {
                    const facetValue = product.facetValues.find(
                        (facetValue) => facetValue.facet.code === key
                    );
                    if (facetValue) {
                        formData[key] = facetValue.name;
                    }
                });
            }
        }

        return formData;
    }, [pageQuery.data]);

    const productTypes: any[] = pageQuery.data?.rtoConfig?.options?.productTypes || [];

    const form = useForm({ defaultValues: initialState });
    const register = form.register;
    const errors = form.formState.errors;

    const onSubmit = async (formData: any) => {
        const { retail_price, ...optionFields } = formData;

        const options: AttributeOption[] = [];

        Object.keys(optionFields).forEach((key) => {
            options.push({
                key: key,
                value: optionFields[key],
            });
        });

        let itemName = formData.style;

        if (formData.size) {
            itemName += ' ' + formData.size;
        }

        const result = await sdk.SetRtoItem({
            input: {
                orderId: pageQuery.data?.order?.id || '',
                retailPrice: Number(retail_price),
                name: itemName,
                options,
            },
        });

        if (result.setRtoItem?.id) {
            // reload
            invalidate(result.setRtoItem.id);
        }
    };

    const handleClickContinue = async () => {
        return form.handleSubmit(onSubmit, () => {
            throw new Error('Invalid form.');
        })();
    };

    return (
        <form>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <VStack alignItems="start" width="100%" spacing={4}>
                    <Box>
                        <Heading size="md">Item details</Heading>
                    </Box>
                    <Stack direction={{ base: 'column', sm: 'row' }} spacing={4} w="100%">
                        {productTypes.length > 1 && (
                            <FormControl isInvalid={!!errors.product_type} flex="1">
                                <FormLabel>Structure Type</FormLabel>
                                <Select {...register('product_type', { required: 'Required.' })}>
                                    {productTypes.map((opt: any) => (
                                        <option key={opt.id} value={opt.name}>{opt.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <FormControl isInvalid={!!errors.retail_price} flex="1">
                            <FormLabel>Retail price</FormLabel>
                            <RhfMoneyInput
                                name="retail_price"
                                control={form.control}
                                rules={{ required: 'Required.' }}
                            />
                            <FormErrorMessage>{errors.retail_price?.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={{ base: 'column', sm: 'row' }} w="100%" spacing={4}>
                        <FormControl isInvalid={!!errors.style}>
                            <FormLabel>Style</FormLabel>
                            <Input {...register('style', { required: 'Required.' })} />
                            <FormErrorMessage>{errors.style?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.size}>
                            <FormLabel>Size</FormLabel>
                            <Input {...register('size', { required: 'Required.' })} />
                            <FormErrorMessage>{errors.size?.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <FormControl isInvalid={!!errors.reference_id}>
                        <FormLabel>Serial Number or ID</FormLabel>
                        <Input {...register('reference_id', { required: 'Required.' })} />
                        <FormErrorMessage>{errors.reference_id?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.siding_color}>
                        <FormLabel>Siding Color</FormLabel>
                        <Input {...register('siding_color', { required: 'Required.' })} />
                        <FormErrorMessage>{errors.siding_color?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.trim_color}>
                        <FormLabel>Trim Color</FormLabel>
                        <Input {...register('trim_color')} />
                        <FormErrorMessage>{errors.trim_color?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.roof_color}>
                        <FormLabel>Roof Color</FormLabel>
                        <Input {...register('roof_color')} />
                        <FormErrorMessage>{errors.roof_color?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Condition</FormLabel>
                        <RhfRadioGroup name="condition" control={form.control}>
                            <HStack spacing={4}>
                                <Radio value="New">New</Radio>
                                <Radio value="Used">Used</Radio>
                            </HStack>
                        </RhfRadioGroup>
                    </FormControl>
                </VStack>
                <ActionBar submitLabel="Continue" onSubmit={handleClickContinue} />
            </VStack>
        </form>
    );
};

export default StepOrderDetails;
