import { MutableRefObject, useState, useEffect, useId } from 'react';
import { Box, Button, Link, Input } from '@chakra-ui/react';
import { IconUpload } from '@tabler/icons';

import { useApiSdk } from 'ui-core';

export type TemplateUploadProps = {
    dataRef?: MutableRefObject<any>;
    setIsLoading?: (value: boolean) => void;
    template?: Record<string, any>;
};

export type FileInputProps = {
    label: string;
    onFileChange: (e: any) => Promise<void>;
    accept?: string;
};

const FileInput = (props: FileInputProps) => {
    const inputId = useId();
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = async (e: any) => {
        setIsUploading(true);

        await props.onFileChange(e);

        setIsUploading(false);
    };

    return (
        <>
            <Button
                variant="outline"
                leftIcon={<IconUpload />}
                isLoading={isUploading}
                position="relative"
            >
                {props.label}
                <Box
                    as="label"
                    display="inline-block"
                    htmlFor={inputId}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    cursor="pointer"
                />
            </Button>
            <Input
                id={inputId}
                onChange={handleFileChange}
                type="file"
                display="none"
                accept={props.accept}
            />
        </>
    );
};

export const TemplateUpload = (props: TemplateUploadProps) => {
    const [fileId, setFileId] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const sdk = useApiSdk();

    useEffect(() => {
        if (props.dataRef) {
            props.dataRef.current.fileId = fileId;
        }
    }, [fileId]);

    useEffect(() => {
        if (!fileName && props.template?.contentFile?.name) {
            setFileName(props.template.contentFile.name);
            setFileUrl(props.template.contentFile.source);
        }
    }, [fileName, props.template]);

    const onFileChange = async (e: any) => {
        if (e.target.files[0]) {
            try {
                const result = await sdk.UploadContractTemplateFile({ file: e.target.files[0] });
                setFileId(result.uploadContractTemplateFile?.id);
                setFileName(result.uploadContractTemplateFile?.name);
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <Box>
            <Box py={4}>{fileUrl ? <Link href={fileUrl} target="_blank">{fileName}</Link> : fileName}</Box>
            <Box>
                <FileInput
                    label="Upload .odt file"
                    onFileChange={onFileChange}
                    accept="application/vnd.oasis.opendocument.text"
                />
            </Box>
        </Box>
    );
};
