import React from 'react';

import { Refine, ResourceProps, TitleProps, useRouterContext } from '@pankod/refine-core';
import {
    notificationProvider,
    ChakraProvider,
    ReadyPage,
    ErrorComponent,
    List,
} from '@pankod/refine-chakra-ui';
import { Box, withDefaultColorScheme } from '@chakra-ui/react';

import {
    DataProvider,
    Header,
    Layout,
    Sider,
    authProvider,
    routerProvider,
    getAuthenticatedClient,
    setDefaultAppModeContext,
    useDynamicTheme,
    MainSection,
} from 'ui-core';
import { AdminList, AdminCreate, AdminEdit } from './pages/admins';
import { TemplateList, TemplateCreate, TemplateEdit } from 'pages/templates';
import { OrganizationList, OrganizationCreate, OrganizationShow, OrganizationEdit } from 'pages/organization';
import { OrderList, CreateOrder, EditOrder, ShowOrder } from 'pages/orders';
import { PaymentList, ShowPayment } from 'pages/payments';
import DashboardPage from 'pages/dashboard';
import { SettingsPage } from 'pages/settings';
import AuthPage from 'pages/auth';
import { IconArticle, IconBuildingCottage, IconBuildingWarehouse, IconSettings, IconUsers, IconCreditCard } from '@tabler/icons';
import { Branding } from 'ui-rto';

declare const appConfig: any;

const client = getAuthenticatedClient(appConfig.apiUrl);
const gqlDataProvider = new DataProvider(client);

const resources: ResourceProps[] = [
  {
    name: 'orders',
    options: {
      label: 'Contracts',
    },
    list: OrderList,
    create: CreateOrder,
    edit: EditOrder,
    show: ShowOrder,
    icon: <IconBuildingWarehouse/>
  },
  {
    name: 'payments',
    options: {
      label: 'Payments',
    },
    list: PaymentList,
    show: ShowPayment,
    icon: <IconCreditCard/>
  },
  {
    name: 'organization',
    options: {
      label: 'Companies',
    },
    list: OrganizationList,
    create: OrganizationCreate,
    show: OrganizationShow,
    edit: OrganizationEdit,
    icon: <IconBuildingCottage/>
  },
  {
    name: 'contractTemplates',
    options: {
      label: 'Templates',
    },
    list: TemplateList,
    create: TemplateCreate,
    edit: TemplateEdit,
    icon: <IconArticle/>
  },
  { 
    name: 'administrator',
    options: {
        label: 'Users',
    },
    list: AdminList,
    create: AdminCreate,
    edit: AdminEdit,
    icon: <IconUsers/>
  },
  {
    name: 'settings',
    options: {
      label: 'Settings',
    },
    list: SettingsPage,
    
    icon: <IconSettings/>
  },
  
  
];

setDefaultAppModeContext({
    pages: {
        superAdmin: ['orders', 'payments', 'organization', 'contractTemplates', 'administrator', 'settings'],
        user: ['orders', 'payments'],
        sellerAdmin: ['orders', 'payments', 'administrator'],
        none: [],
    },
});




const imageTitle: React.FC<TitleProps> = ({ collapsed }) => {
    const { Link } = useRouterContext();

    return (
        <Box>
            <Link to="/">
                {collapsed ? (
                    <img src={Branding.titleImageCollapsed} alt={Branding.name} />
                ) : (
                    <img src={Branding.titleImage} alt={Branding.name} width="140px" />
                )}
            </Link>
        </Box>
    );
};

function App() {
    const theme = useDynamicTheme(withDefaultColorScheme({ colorScheme: appConfig?.theme?.colorScheme || 'blue' }));

    return (
        <ChakraProvider theme={theme}>
            <Refine
                notificationProvider={notificationProvider()}
                ReadyPage={ReadyPage}
                catchAll={<ErrorComponent />}
                routerProvider={{
                  ...routerProvider,
                  routes: [
                      {
                          path: "/forgot-password",
                          element: <AuthPage type="forgotPassword" />,
                      },
                      {
                          path: "/password-reset",
                          element: <AuthPage type="updatePassword" />,
                      },
                  ],
                }}
                dataProvider={gqlDataProvider}
                authProvider={authProvider(client)}
                LoginPage={AuthPage}
                Title={imageTitle}
                Sider={Sider}
                Layout={Layout}
                Header={Header}
                DashboardPage={DashboardPage}
                resources={resources}
                options={{
                    warnWhenUnsavedChanges: true,
                    disableTelemetry: true,
                }}
            />
        </ChakraProvider>
    );
}

export default App;
