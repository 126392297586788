import {
    Button,
    HStack,
    Show,
    Text,
    Image,
    Stack,
    Heading,
    NumberField,
    StackDivider,
    Box,
    DateField,
    Input,
    InputGroup,
    InputRightElement,
    Flex,
    Checkbox,
    IconButton,
    Badge,
    Spacer,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useDisclosure
} from '@pankod/refine-chakra-ui';
import { Card, CardHeader, CardBody } from '@chakra-ui/react'
import { IResourceComponentsProps, useInvalidate, useNavigation, useShow } from "@pankod/refine-core";
import { ReactNode, useEffect, useState } from "react";
import { MainSection, useCurrentAdmin, getStatusString, getStatusLabel, useApiSdk } from 'ui-core';
import { DeliveryModal, DocumentsBox, PaymentsBox } from 'ui-rto';
import { IconTrash } from "@tabler/icons";

const STATUS_PENDING = "PendingApproval";
const STATUS_APPROVED = "Approved";


 interface HistoryItem {
    id: string,
    type: string,
    createdAt: string,
    isPublic: boolean,
    administrator: {
        id: string,
        firstName: string,
        lastName: string,
    },
    data :{
        note: string,
    }
}

export const ShowOrder: React.FC<IResourceComponentsProps> = () => {

    const [postNote, setPostNote] = useState("");
    const [postNotePrivate, setPostNotePrivate] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isModifying, setIsModifying] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: statusModalIsOpen,
        onOpen: statusModalOnOpen,
        onClose: statusModalOnClose
    } = useDisclosure();
    const [status, setStatus] = useState("");
    const navMethods = useNavigation();

    const adminQuery = useCurrentAdmin();
    const admin = adminQuery.data?.data;

    const onVarTitleChange = (event: any) => {
        if(event.target.value && event.target.value){
            setPostNote(event.target.value);
            
        }
    }

    const handleOnChnagePostPrivate = () => {
        setPostNotePrivate(!postNotePrivate);
        
    }
    
    const sdk = useApiSdk();

    const onApproveClick = () => {
        
        approveOrder()
    }
    const onCancelClick = () => {
        
        cancelOrder();
    }

    const onCancelRequestClick = () => {
        
        onOpen();
    }

    const invalidate = useInvalidate();
    const { queryResult } = useShow();
    const { data } = queryResult;

    const id: string = (data?.data.id || '').toString();

    
    let historyItems: HistoryItem[] = [];
    const [orderHistory, setOrderHistory] = useState(historyItems);
    const [orderNotes, setOrderNotes] = useState(historyItems);

    useEffect(() => {
        getOrderHistory(true);
    }, [data]);

    async function cancelOrder() {
        try {
            const result = await sdk.CancelOrder({
                input:{
                    orderId: id as string,
                    reason: "Admin Cancelled",
                    cancelShipping: true,
                    lines: [{
                        orderLineId: data?.data?.lines[0].id as string,
                        quantity: data?.data?.lines[0].quantity as number,
                    }]

                  }  
            });

        } catch(error) {
            console.error(error);
        }

        invalidate({
            resource: "orders",
            invalidates: ["list", "detail"],
            id: id,
        });
    }

    async function approveOrder() {
        try {
            const transitionResult = await sdk.TransitionOrderToState({
                id: id as string,
                state: "Approved"
            });
            
        } catch(error) {
            console.error(error);
        }

        invalidate({
            resource: "orders",
            invalidates: ["list", "detail"],
            id: id,
        });
    }
    
    async function getOrderHistory(isRefresh: boolean) {
        if( id ){
            if(orderHistory.length === 0 || isRefresh) {
                try{
                    const result = await sdk.GetOrderHistory({
                        id: id as string
                    });
                    
                    
                    let noteItems: HistoryItem[] = [];
                    result?.order?.history.items.forEach( (item) => {
                        if(item.type === "ORDER_NOTE") {
                            noteItems.push(item as HistoryItem);
                        }
                    });
                    setOrderHistory(result?.order?.history.items as HistoryItem[]);
                    setOrderNotes(noteItems);

                    
                    return result;
                }catch(error) {
                    console.log(error);
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    async function deleteNote(id: string) {
        const result = await sdk.DeleteOrderNote({
            id: id as string
        })
        getOrderHistory(true);
    }

    async function doPostNote(note:string, isPrivate: boolean) {

        const result = await sdk.AddNoteToOrder({
            input : {
                id: id as string,
                note: note,
                isPublic: isPrivate,
            }
        });
        setPostNote(""); 
        getOrderHistory(true);     
    }
    
    function onPostClick() {
        if(postNote && postNote.length > 0) {
            doPostNote(postNote, postNotePrivate);
        }
    }

    function onNoteDelete(id: string) {
        
        deleteNote(id);
    }

    function getDeleteButton(noteItem: HistoryItem) {
        if(admin?.lastName === noteItem?.administrator?.lastName && admin?.firstName === noteItem?.administrator?.firstName ) {
            return (
                <Box p='4'>
                    <IconButton
                        variant='outline'
                        colorScheme='red'
                        aria-label="Delete"
                        icon={<IconTrash/>}
                        size='xs'
                        onClick={ (e) => onNoteDelete(noteItem.id)}
                    />
                </Box>
            );
        }
        return null;
    }

    const onProcessClick = async () => {
        try {
            const transitionResult = await sdk.TransitionOrderToState({
                id: id as string,
                state: "Processed"
            });
            
        } catch(error) {
            console.error(error);
        }

        invalidate({
            resource: "orders",
            invalidates: ["list", "detail"],
            id: id,
        });
    }

    const handleSubmitRequestCancel = async () => {
        setIsLoading(true);

        const message = "Cancellation Request: " + postNote;

        try {
            const result = await sdk.AddNoteToOrder({
                input : {
                    id: id as string,
                    note: message,
                    isPublic: true,
                }
            });
            const transitionResult = await sdk.TransitionOrderToState({
                id: id as string,
                state: "PendingCancellation"
            });
        } catch (e) {
            console.error(e);
        }

        setPostNote(""); 

        invalidate({
            resource: "orders",
            invalidates: ["list", "detail"],
            id: id,
        });

        // wait for the data to be refreshed after invalidate
        setTimeout(() => {
            setIsLoading(false);
            onClose();
        }, 300);
    }

    const onStatusChange = (event: any) => {
        setStatus(event.target.value);
    }

    const handleSubmitStatusChange = async () => {
        setIsLoading(true);

        try {
            await sdk.TransitionOrderToState({
                id: id.toString(),
                state: status,
            });
            invalidate({
                resource: "orders",
                invalidates: ["detail"],
                id: id,
            });
            // wait for the data to be refreshed after invalidate
            await new Promise(resolve => setTimeout(resolve, 300));
        } catch (e) {
            console.error(e);
        }

        setIsLoading(false);
        statusModalOnClose();
    }

    const onModify = async () => {
        setIsModifying(true);

        try {
            await sdk.TransitionOrderToState({
                id: id.toString(),
                state: 'Modifying',
            });

            navMethods.edit("orders", id.toString());
        } catch (e) {
            console.error(e);
        }

        setIsModifying(false);
    }

    const isAdmin = admin?.user?.roles[0]?.channels[0]?.code === "__default_channel__";
    const orderStatus = data?.data?.state;
    let nextStates: string[] = data?.data?.nextStates || [];
    nextStates = nextStates.filter((s: string) => s != 'PendingCancellation' && s != 'Cancelled');
    const deliveryDate = data?.data?.lines[0]?.fulfillments[0]?.createdAt || '';
    const canBeDelivered = orderStatus === STATUS_APPROVED || orderStatus === STATUS_PENDING;
    const canBeCanceled = !['Draft','Cancelled'].includes(orderStatus);
    const headerButtonList: ReactNode[] = [];

    if (canBeCanceled) {
        if (!isAdmin && [STATUS_APPROVED,'Delivered','Processed', 'PendingCancellation'].includes(orderStatus)) {
            if (orderStatus != 'PendingCancellation') {
                headerButtonList.push(
                    <Button
                        key="cancel"
                        variant="ghost"
                        colorScheme="red"
                        onClick={onCancelRequestClick}
                    >
                        Request Cancellation
                    </Button>
                );
            }
        } else {
            headerButtonList.push(
                <Button
                    key="cancel"
                    variant="ghost"
                    colorScheme="red"
                    onClick={onCancelClick}
                >
                    Cancel Contract
                </Button>
            );
        }
    }

    if (nextStates.includes('Modifying') && (isAdmin || ['ArrangingPayment', 'PendingSignature'].includes(orderStatus))) {
        headerButtonList.push(
            <Button key="status" variant="outline" onClick={onModify} isLoading={isModifying}>
                Modify
            </Button>
        );
    }

    if (canBeDelivered) {
        headerButtonList.push(
            <DeliveryModal key="delivery" orderId={id} />
        );
    }

    if (isAdmin && orderStatus == 'Delivered') {
        headerButtonList.push(
            <Button key="process" variant="outline" onClick={onProcessClick}>Mark as Processed</Button>
        );
    }

    if (isAdmin && orderStatus === STATUS_PENDING) {
        headerButtonList.push(
            <Button key="approve" variant="outline" onClick={onApproveClick}>Accept</Button>
        );
    }

return (
    <MainSection variant="form">
        <Show
            title="Contract Details"
            canDelete
            headerButtons={() => null}
            breadcrumb={null}
        >
            {headerButtonList.length > 0 && (
                <Flex
                    borderTop="1px"
                    borderBottom="1px"
                    borderColor="gray.200"
                    py={4}
                    mb={4}
                    alignItems="center"
                    justifyContent="end"
                >
                    <HStack flexWrap="wrap">
                        {headerButtonList}
                    </HStack>
                </Flex>
            )}
            <Card>
                <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                        <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            Status
                            </Heading>
                            <HStack pt="2" fontSize="sm" spacing={4}>
                                {getStatusString(data?.data.state)}
                                {isAdmin && (
                                    <Button onClick={statusModalOnOpen} variant="link" size="sm">
                                        Change
                                    </Button>
                                )}
                            </HStack>
                        </Box>
                        <Stack direction="row">
                            <Box flex="1">
                                <Heading size='xs' textTransform='uppercase'>
                                    Created On
                                </Heading>
                                <Flex pt='2' fontSize='sm'>
                                    <DateField value={data?.data.createdAt as string} format="LLL" mr={4} />
                                    {data?.data.createdByName && <Box>by <Box as="span" fontWeight="medium">{data?.data.createdByName}</Box></Box>}
                                </Flex>
                            </Box>
                            <Box flex="1">
                                <Heading size='xs' textTransform='uppercase'>
                                    Updated On
                                </Heading>
                                <Box pt='2' fontSize='sm'>
                                    <DateField value={data?.data.updatedAt as string} format="LLL" />
                                </Box>
                            </Box>
                        </Stack>
                        {!!deliveryDate && (
                            <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                    Delivered On
                                </Heading>
                                <Box pt='2' fontSize='sm'>
                                    <DateField value={deliveryDate} format="LL" />
                                </Box>
                            </Box>
                        )}
                    </Stack>
                </CardBody>
            </Card>
            <DocumentsBox order={data?.data} />
            <Card>
                <CardHeader>
                    <Heading size='md'>Product</Heading>
                </CardHeader>
                <CardBody>
                    <Image
                        src={data?.data?.lines[0]?.featuredAsset?.preview}
                        alt={data?.data?.lines[0]?.productVariant?.name}
                        borderRadius='lg'
                    />
                    <Stack mt='6' spacing='3'>
                    <Heading size='md'>{data?.data?.lines[0]?.productVariant?.name}</Heading>
                    <Text>
                        
                    </Text>
                    <Box color='blue.600' fontSize='2xl'>
                        <NumberField options={{ style: 'currency', currency: 'USD' }} value={data?.data?.totalWithTax/100} />
                    </Box>
                    </Stack>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size='md'>Customer</Heading>
                </CardHeader>

                <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Name
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {data?.data.customer?.firstName + " " + data?.data.customer?.lastName}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Delivery Address
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {data?.data.shippingAddress.streetLine1}
                            <br/>
                            {data?.data.shippingAddress.city +", " + data?.data.shippingAddress.province + " " + data?.data.shippingAddress.postalCode}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Customer Contact Details
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                           Phone# {data?.data.shippingAddress.phoneNumber ? data?.data.shippingAddress.phoneNumber : "NA" }
                        </Text>
                        <Text pt='2' fontSize='sm'>
                           Email: {data?.data.shippingAddress.email ? data?.data.shippingAddress.email: "NA"}
                        </Text>
                    </Box>
                    </Stack>
                </CardBody>
            </Card>
            {data?.data && data?.data?.payments.length && (
                <PaymentsBox order={data?.data} />
            )}
            <Card>
                <CardHeader>
                    <Heading size='md'>Comments</Heading>
                </CardHeader>

                {orderNotes?.map((item: HistoryItem) =>     
                    <Box key={item.id} bg={'white'} borderRadius={'xl'} my={1} p={1} boxShadow={'sm'}>
                        <Flex alignItems={'center'}> 
                            <Text fontWeight={'bold'} mx={3} fontSize={'sm'}>
                                {item?.administrator?.firstName + " " + item?.administrator?.lastName}
                            </Text>
                            
                            <DateField color={"gray"} mx={3} fontSize={'xs'} value={item?.createdAt} format="LLL" />
                            
                            <Badge
                                size='xs'
                                variant='outline'
                                colorScheme={item.isPublic? 'green' : 'yellow'}
                            >
                                {item.isPublic? "Public" : "Admin Only"}
                            </Badge>
                        </Flex>
                        <Flex>
                            <Box p='4'>
                                <Text color={"gray"} fontWeight='semibold' mx ={3} my={1}>
                                    {item?.data?.note}
                                </Text>
                            </Box>
                            <Spacer/>
                            {getDeleteButton(item)}
                        </Flex>
                    </Box>
                        
                )}
                
                <CardBody>
                    <InputGroup size='md'>
                        <Input
                            pr='4.5rem'
                            type='text'
                            placeholder='Enter comment'
                            id="note"
                            onChange={onVarTitleChange}
                            value={postNote}
                        />
                        <InputRightElement width='4.5rem'>
                            <Button color="green" h='1.75rem' size='sm' onClick={ () => {onPostClick()}}>
                                Post
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Flex justify="flex-end">
                        <Checkbox
                            size="sm"
                            isChecked={postNotePrivate}
                            onChange={handleOnChnagePostPrivate}
                            id="private"
                            name="private"
                        >
                            Note is Public
                        </Checkbox>
                    </Flex>
                   
                </CardBody>
            </Card>

           
        </Show>
        <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={ () => {
            setPostNote('');
            onClose() }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cancellation Request</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel>Reason For Cancellation</FormLabel>
                            <Input 
                                 id="note"
                                 onChange={onVarTitleChange}
                                 value={postNote}/>
                        </FormControl>
                       
                        
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={handleSubmitRequestCancel} isLoading={isLoading}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={statusModalIsOpen} onClose={statusModalOnClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Change Status</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Status</FormLabel>
                            <Select 
                                id="status"
                                onChange={onStatusChange}
                                >
                                    <option value="">Select next status</option>
                                    {nextStates.map((state) => 
                                        <option key={state} value={state}>
                                            {getStatusLabel(state)}
                                        </option>
                                    )}
                                </Select>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={statusModalOnClose} variant="outline" mr={3}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmitStatusChange} isLoading={isLoading}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>

            </Modal>
        </MainSection>
    );
};