import { Badge } from "@pankod/refine-chakra-ui";

const labelMap: Record<string, { label: string; color?: string; }> = {
    Draft: {
        label: "Draft",
        color: "blue",
    },
    ArrangingPayment: {
        label: "Arranging Payment",
        color: "yellow",
    },
    PaymentSettled: {
        label: "Payment Settled",
        color: "yellow",
    },
    PendingSignature: {
        label: "Pending Signature",
        color: "orange",
    },
    PendingApproval: {
        label: "Pending Approval",
        color: "orange",
    },
    Approved: {
        label: "Accepted",
        color: "green",
    },
    Delivered: {
        label: "Delivered",
        color: "orange",
    },
    Processed: {
        label: "Processed",
        color: "green",
    },
    Cancelled: {
        label: "Cancelled",
        color: "red",
    },
};

export function getStatusLabel(code: string) {
    return labelMap[code]?.label || code;
}

export function getStatusString(code: string) {
    return (
        <Badge size="xs" variant="outline" colorScheme={labelMap[code]?.color || "gray"}>
            {labelMap[code]?.label || code}
        </Badge>
    );
}
