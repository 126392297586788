import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Edit, useDisclosure } from "@pankod/refine-chakra-ui";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";
import React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import { MainSection, TemplateAccordian, useApiSdk, validateTemplateInputs } from "ui-core";
import { useContractSettings } from "ui-rto";
import type { ContractTemplate } from "api-client/types";

declare const appConfig: any;
let contentDefinitionMode: 'editor' | 'upload' = appConfig.contentDefinitionMode || 'upload';

export const TemplateEdit: React.FC<IResourceComponentsProps>  = () => {
    
    //const resources = useResource();
    //console.log("ID: " + resources.id);

    const {
        refineCore: { formLoading, queryResult },
    } = useForm();


    const template: ContractTemplate = queryResult?.data?.data as ContractTemplate;
    
    const [isLoading, setIsLoading] = useState(false);
    const [templateTitle, setTemplateTitle] = useState("");
    const [templateState, setTemplateStates] = useState("");
    const [templateCompanies, setTemplateCompanies] = useState("");
    const [templateContents, setTemplateContents] = useState("");
    const [templateVariables, setTemplateVariables] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const dataRef = useRef<any>({});
    const settingsDataRef = useRef<any>(template?.settings);
    const defaultSettingsQuery = useContractSettings();

    useEffect(() => {
        setTemplateTitle(template?.title);
        setTemplateStates(template?.states || '');
        setTemplateCompanies(template?.companies || '');
        setTemplateContents(template?.content as string);
        setTemplateVariables(template?.variables as string);
    }, [template]);


    const { isOpen, onOpen, onClose } = useDisclosure();
    const okRef = React.useRef()

    const titleChange = (arg: string) => {
        setTemplateTitle(arg);
    }

    const statesChange = (arg: string) => {
        setTemplateStates(arg);
    }

    const companiesChange = (arg: string) => {
        setTemplateCompanies(arg);
    }

    const contentChange = (arg: string) => {
        setTemplateContents(arg);
    }

    const variablesChange = (arg: string) => {
        setTemplateVariables(arg);
    }
    
    
    const navMethods = useNavigation();
    async function onSaveCLick() {
        setIsLoading(true);
    
        if (contentDefinitionMode === 'editor') {
            const errorMsg = validateTemplateInputs(templateTitle, templateState, templateCompanies, templateContents, templateVariables);

            if (errorMsg && errorMsg.length > 0) {
                setErrorMessage(errorMsg);
                onOpen();
                return;
            }
        }

        try {
            await doUpdateTemplate();
        } catch (e) {
            if (e instanceof Error) {
                setErrorMessage(e.message);
                onOpen();
            }
        } finally {
            setIsLoading(false);
        }
    }

    const sdk = useApiSdk();

    async function doUpdateTemplate() {
        try{
            const result = await sdk.UpdateContractTemplate({ 
                input: {
                    id: template.id as string,
                    title: templateTitle,
                    states: templateState,
                    companies: templateCompanies,
                    content: templateContents,
                    variables: templateVariables,
                    settings: settingsDataRef.current,
                    contentFileId: dataRef.current?.fileId || '',
                    productTypes: settingsDataRef.current?.productTypes || [],
                }
            });
            
            navMethods.list("contractTemplates");
        } catch(error) {
            console.error(error);
            //todo: handle error display
        }
    }

    return (
        <MainSection variant="form">
            <Edit 
                title="Edit Template" 
                saveButtonProps={{onClick: () => onSaveCLick()}}
                isLoading={isLoading}
            >
                { formLoading === false ? (
                    <TemplateAccordian
                        setIsLoading={setIsLoading}
                        setTitle={titleChange}
                        setStates={statesChange}
                        setCompanies={companiesChange}
                        setVariables={variablesChange}
                        setCotnent={contentChange}
                        template={template}
                        settingsDataRef={settingsDataRef}
                        defaultSettings={defaultSettingsQuery.data}
                        contentDefinitionMode={contentDefinitionMode}
                        dataRef={dataRef}
                    />
                
                ): (<>Loading</>)}
            </Edit>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={okRef as any}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Required Content
                    </AlertDialogHeader>
        
                    <AlertDialogBody>
                        {errorMessage}
                    </AlertDialogBody>
        
                    <AlertDialogFooter>
                        <Button ref={okRef as any} onClick={onClose}>
                            OK
                        </Button>
                    
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </MainSection>
);
};