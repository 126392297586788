import { useMemo, useState } from 'react';
import {
    Box,
    FormLabel,
    FormControl,
} from '@chakra-ui/react';
import { useList } from '@pankod/refine-core';
import { useController } from 'react-hook-form';
import { Select as CustomSelect} from 'chakra-react-select';

export const RtoProviderField = (props: { control: any; }) => {
    const [selectedOrg, setSelectedOrg] = useState<any>();
    const orgListResult = useList<{ id: string; name: string; }>({
        resource: "organization",
        config: {
            sort: [{ order: "asc", field: "name" }],
            filters: [
                {
                    field: "type",
                    operator: "eq",
                    value: "rto-provider",
                }
            ]
        },
    });

    const organizations = useMemo(() => {
        if (!orgListResult.data?.data) {
            return [];
        }
        return orgListResult.data.data.map((org) => ({
            id: org.id,
            value: org.id,
            label: org.name,
        }));
    }, [orgListResult.data]);

    const fieldController = useController({
        name: 'parentId',
        control: props.control,
        rules: { validate: (value: any) => {
            if (!orgListResult.isLoading && (orgListResult.data?.data?.length || 0) < 2) {
                return true;
            }
            if (!value) {
                return 'Required.';
            }
        } },
    });

    const onChange = (option: any) => {
        setSelectedOrg(option);
        fieldController.field.onChange(option.value);
    };

    if (!orgListResult.isLoading && organizations.length < 2) {
        return null;
    }

    return (
        <Box>
            <FormControl>
                <FormLabel>RTO Provider</FormLabel>
                <CustomSelect
                    value={selectedOrg}
                    name={fieldController.field.name}
                    onChange={onChange}
                    placeholder="Select" 
                    options={organizations}
                />
            </FormControl>
        </Box>
    );
};

export default RtoProviderField;
