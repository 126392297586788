import { useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    IconButton,
    Input,
    InputGroup,
    InputRightAddon,
    Radio,
    RadioGroup,
    Stack,
    VStack,
    Skeleton,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

import { ActionBar, MoneyInput } from 'ui-core';

import { useRtoOrderFormData } from '../../../hooks/use-rto-order-form-data';
import { useSetRtoFields } from '../../../hooks/use-set-rto-fields';
import { formatPrice } from '../../../utilities';

export const StepTerm = () => {
    const pageQuery = useRtoOrderFormData();

    const order = pageQuery.data?.order;

    const [selectedTerm, setSelectedTerm] = useState(order?.rtoContract?.term || '');
    const [addLdw, setAddLdw] = useState(false);
    const [enableGreaterPayment, setEnableGreaterPayment] = useState(false);
    const [greaterInitialPayment, setGreaterInitialPayment] = useState(0);
    const [securityDeposit, setSecurityDeposit] = useState<string | number>('');
    const [taxRate, setTaxRate] = useState<string | number>('');

    const terms = pageQuery.data?.rtoTermsForOrder || [];
    const selectedTermData = terms.find((value: any) => value.term == Number(selectedTerm));
    const processingFee = pageQuery.data?.rtoConfig?.options?.processingFee || 0;

    useEffect(() => {
        if (order) {
            setSelectedTerm(order?.rtoContract?.term || '');
            setEnableGreaterPayment(order?.rtoContract?.customInitialPayment > 0 || false);
            setGreaterInitialPayment(order?.rtoContract?.customInitialPayment || 0);
            setTaxRate(
                order?.rtoContract?.taxRate !== undefined
                    ? (order?.rtoContract?.taxRate * 100).toFixed(2)
                    : ''
            );
            setAddLdw(Boolean(order?.rtoContract?.ldw));

            if (order?.rtoContract?.securityDeposit !== undefined) {
                setSecurityDeposit(order.rtoContract.securityDeposit);
            }
        }
    }, [order]);

    const rtoContractMutation = useSetRtoFields();

    const handleChange = async (val: string) => {
        setSelectedTerm(val);

        if (order) {
            rtoContractMutation.mutate({
                orderId: order.id,
                term: val,
            });
        }
    };

    const handleChangeEnableGreaterPayment = (e: any) => {
        let isEnabled = e.target.checked;
        setEnableGreaterPayment(isEnabled);

        if (order) {
            let defaultAmount = -1;

            if (isEnabled) {
                setGreaterInitialPayment(order?.rtoContract?.originalInitialPayment);
                defaultAmount = order?.rtoContract?.originalInitialPayment;
            }

            rtoContractMutation.mutate({
                orderId: order.id,
                customInitialPayment: defaultAmount,
            });
        }
    };

    const handleChangeGreaterPayment = (value: any) => {
        const initialPayment = selectedTermData?.initialPayment || 0;
        if (value.floatValue > initialPayment || value.floatValue === 0) {
            setGreaterInitialPayment(value.floatValue);
        }
    };

    const onBlurGreaterPayment = (e: any) => {
        if (order) {
            const initialPayment = selectedTermData?.initialPayment || 0;

            if (greaterInitialPayment > initialPayment || greaterInitialPayment === 0) {
                rtoContractMutation.mutate({
                    orderId: order.id,
                    customInitialPayment: greaterInitialPayment,
                });
            } else {
                setGreaterInitialPayment(initialPayment);
            }
        }
    };

    const handleChangeSecurityDeposit = (value: any) => {
        setSecurityDeposit(value.floatValue);
    };

    const updateSecurityDeposit = () => {
        if (order) {
            rtoContractMutation.mutate({
                orderId: order.id,
                securityDeposit: securityDeposit,
            });
        }
    };

    const handleChangeLdw = (e: any) => {
        if (order) {
            setAddLdw(e.target.checked);

            rtoContractMutation.mutate({
                orderId: order.id,
                addLdw: e.target.checked,
            });
        }
    };

    const handleChangeTaxRate = (e: any) => {
        setTaxRate(e.target.value);
    };

    const onBlurTaxRate = (e: any) => {
        if (order) {
            rtoContractMutation.mutate({
                orderId: order.id,
                taxRate: Number(taxRate) / 100,
            });
        }
    };

    const handleClickContinue = () => {
        if (!selectedTerm) {
            throw new Error('No term selected.');
        }
        if (taxRate === '') {
            throw new Error('No tax rate specified.');
        }
    };

    const handleClickRefreshTax = () => {
        if (order) {
            rtoContractMutation.mutate({
                orderId: order.id,
                taxRate: -1,
            });
        }
    };

    if (!pageQuery.data) {
        return null;
    }

    return (
        <Box>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <Box>
                    <FormControl borderBottom="1px" borderBottomColor="gray.200" pb={3}>
                        <RadioGroup value={`${selectedTerm}`} onChange={handleChange}>
                            <Stack spacing={4}>
                                {terms.map((termData) => {
                                    return (
                                        <Radio
                                            size="lg"
                                            borderColor="gray.300"
                                            key={termData.term}
                                            value={`${termData.term}`}
                                        >
                                            <Box
                                                as="span"
                                                fontWeight="semibold"
                                            >{`$${termData.recurringPayment.toFixed(2)}`}</Box>
                                            {` for ${termData.term} months`}
                                        </Radio>
                                    );
                                })}
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                    {order?.rtoContract?.initialPayment !== undefined && processingFee > 0 && (
                        <Box py={4} borderBottom="1px" borderBottomColor="gray.200">
                            <Box>One time origination fee: {formatPrice(processingFee)}</Box>
                        </Box>
                    )}
                    {!!selectedTermData?.ldwPrice && (
                        <Box py={4} borderBottom="1px" borderBottomColor="gray.200">
                            <Checkbox isChecked={addLdw} onChange={handleChangeLdw}>
                                Include liability damage waiver +{' '}
                                {formatPrice(selectedTermData?.ldwPrice)}/mo
                            </Checkbox>
                        </Box>
                    )}
                    {selectedTermData?.enableSecurityDeposit && (
                        <Box py={4} borderBottom="1px" borderBottomColor="gray.200" fontSize="md">
                            <Flex alignItems="center">
                                <Box>Security deposit:</Box>
                                <Box mr={6}>
                                    <MoneyInput
                                        value={securityDeposit}
                                        onValueChange={handleChangeSecurityDeposit}
                                        onBlur={updateSecurityDeposit}
                                        onKeyUp={(e) => e.key == 'Enter' && updateSecurityDeposit()}
                                        size="sm"
                                        width="120px"
                                        ml={2}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    )}
                    {order?.rtoContract?.initialPayment !== undefined && (
                        <Box py={4} borderBottom="1px" borderBottomColor="gray.200" fontSize="md">
                            <Flex alignItems="center">
                                <Box>
                                    Initial payment:{' '}
                                    {enableGreaterPayment ? (
                                        ''
                                    ) : (
                                        <Box as="span" fontWeight="bold">
                                            {formatPrice(order?.rtoContract?.initialPayment)}
                                        </Box>
                                    )}
                                </Box>
                                <Box mr={6}>
                                    {enableGreaterPayment && (
                                        <MoneyInput
                                            value={greaterInitialPayment}
                                            onValueChange={handleChangeGreaterPayment}
                                            onBlur={onBlurGreaterPayment}
                                            size="sm"
                                            width="120px"
                                            ml={2}
                                        />
                                    )}
                                </Box>
                                {!selectedTermData?.greaterPaymentOptions?.disabled && (
                                    <Checkbox
                                        isChecked={enableGreaterPayment}
                                        onChange={handleChangeEnableGreaterPayment}
                                    >
                                        Greater initial payment
                                    </Checkbox>
                                )}
                            </Flex>
                        </Box>
                    )}
                    <Box py={4} borderBottom="1px" borderBottomColor="gray.200" fontSize="md">
                        <Flex alignItems="center">
                            <Box>Tax rate</Box>
                            <InputGroup size="sm" width="130px" mr={1}>
                                <Input
                                    value={taxRate}
                                    onChange={handleChangeTaxRate}
                                    onBlur={onBlurTaxRate}
                                    size="sm"
                                    ml={2}
                                />
                                <InputRightAddon children="%" />
                            </InputGroup>
                            <IconButton
                                variant="ghost"
                                icon={<RepeatIcon w="16px" h="16px" />}
                                aria-label="Refresh tax rate"
                                size="sm"
                                onClick={handleClickRefreshTax}
                            />
                        </Flex>
                    </Box>
                </Box>
                <ActionBar
                    isLoading={pageQuery.isRefetching || rtoContractMutation.isLoading}
                    onSubmit={handleClickContinue}
                />
            </VStack>
        </Box>
    );
};

export default StepTerm;
