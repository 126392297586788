import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Stack,
    VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { ActionBar, AppEvents, RhfMoneyInput, useApiSdk, useAppModeData } from 'ui-core';
import { formatPrice, formatNumberOrdinal } from '../../../utilities';
import {
    useRtoOrderFormData,
    useInvalidateRtoOrderData,
} from '../../../hooks/use-rto-order-form-data';
import { useSetRtoFields } from '../../../hooks/use-set-rto-fields';
import { ClearentCreditCard, loadClearentScripts } from '../payment-methods/clearent-credit-card';

function useOnStepSubmit(
    form: any,
    callback: (formData: any) => void,
    onInvalid?: (formData: any) => void
) {
    useEffect(() => {
        if (!onInvalid) {
            onInvalid = () => {
                throw new Error('Invalid form.');
            };
        }

        const handler = async (stepId: string) => {
            if (stepId === 'payment') {
                // todo: validate

                await form.handleSubmit(callback, onInvalid)();
            }
        };

        AppEvents.on('before_submit_step', handler);

        return () => {
            AppEvents.off('before_submit_step', handler);
        };
    }, [form]);
}

const PaymentMethodCash = (props: any) => {
    const form = useForm();
    const errors = form.formState.errors;

    useOnStepSubmit(form, (data) => {
        console.log('cash');
        console.log(data);
        props.parentState.paymentMethodResult.current = {
            code: 'cash',
            ...data,
        };
    });

    return (
        <Box pb={4}>
            <form>
                <Stack direction="row" spacing={4}>
                    <FormControl w={{ base: '100%', md: '50%' }} isInvalid={!!errors.amount}>
                        <FormLabel mb={1}>Amount</FormLabel>
                        <RhfMoneyInput
                            name="amount"
                            control={form.control}
                            rules={{ required: 'Required.' }}
                        />
                        <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                    </FormControl>
                </Stack>
            </form>
        </Box>
    );
};

const PaymentMethodCheck = (props: any) => {
    const form = useForm();
    const register = form.register;
    const errors = form.formState.errors;

    useOnStepSubmit(form, (data) => {
        console.log('check');
        console.log(data);
        props.parentState.paymentMethodResult.current = {
            code: 'check',
            ...data,
        };
    });

    return (
        <Box pb={4}>
            <form>
                <Stack direction="row" spacing={4}>
                    <FormControl isInvalid={!!errors.amount}>
                        <FormLabel mb={1}>Amount</FormLabel>
                        <RhfMoneyInput
                            name="amount"
                            control={form.control}
                            rules={{ required: 'Required.' }}
                        />
                        <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.check_number}>
                        <FormLabel fontWeight="normal" mb={1}>
                            Check number
                        </FormLabel>
                        <Input
                            type="number"
                            {...register('check_number', { required: 'Required.' })}
                        />
                        <FormErrorMessage>{errors.check_number?.message}</FormErrorMessage>
                    </FormControl>
                </Stack>
            </form>
        </Box>
    );
};

const PaymentMethodOfflineCard = (props: any) => {
    const form = useForm({
        defaultValues: {
            amount: props.parentState.order?.rtoContract?.initialPayment || 0,
            reference: '',
        },
    });
    const register = form.register;
    const errors = form.formState.errors;

    useOnStepSubmit(form, (data) => {
        console.log('ofline card');
        console.log(data);
        props.parentState.paymentMethodResult.current = {
            code: 'offline-credit-card',
            ...data,
        };
    });

    return (
        <Box pb={4}>
            <form>
                <Stack spacing={4} justifyContent="left">
                    <Box>
                        Amount:{' '}
                        <strong>
                            {formatPrice(props.parentState.order?.rtoContract?.initialPayment || 0)}
                        </strong>
                    </Box>
                    <FormControl>
                        <FormLabel fontWeight="normal" mb={1}>
                            Reference
                        </FormLabel>
                        <Input {...register('reference')} />
                        <FormErrorMessage>{}</FormErrorMessage>
                    </FormControl>
                </Stack>
            </form>
        </Box>
    );
};

const PaymentMethodCard = (props: any) => {
    return (
        <Box pb={4}>
            <ClearentCreditCard config={props.parentState.config} parentState={props.parentState} />
        </Box>
    );
};

export const paymentMethods = [
    {
        id: 'card',
        label: 'Online Payment',
        component: PaymentMethodCard,
        onLoadScripts: loadClearentScripts,
    },
    {
        id: 'manualcredit',
        label: 'External Payment',
        component: PaymentMethodOfflineCard,
    },
    // {
    //     id: 'check',
    //     label: 'Check',
    //     component: PaymentMethodCheck,
    // },
    // {
    //     id: 'cash',
    //     label: 'Cash',
    //     component: PaymentMethodCash,
    // },
];

const PaymentMethodContent = ({ parentState }: any) => {
    const MethodComponent = paymentMethods.find(
        (m) => m.id == parentState.paymentMethodId
    )?.component;

    return MethodComponent ? <MethodComponent parentState={parentState} /> : null;
};

const PaymentMethods = (props: any) => {
    const handleSetPaymentMethod = (e: any) => {
        props.parentState.setPaymentMethodId(e.target.value);
    };

    return (
        <Box>
            {props.parentState.enableOfflineMethods && (
                <FormControl pb={4}>
                    <FormLabel fontWeight="normal" mb={1}>
                        Payment method
                    </FormLabel>
                    <Select
                        value={props.parentState.paymentMethodId}
                        onChange={handleSetPaymentMethod}
                        maxWidth="250px"
                        borderRadius="3px"
                        borderColor="gray.300"
                    >
                        <option value="">Select a payment method</option>
                        {paymentMethods.map((method) => (
                            <option key={method.id} value={method.id}>
                                {method.label}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            )}
            <PaymentMethodContent parentState={props.parentState} />
        </Box>
    );
};

export const StepPayment = () => {
    const [paymentMethodId, setPaymentMethodId] = useState('card');
    const [billingDay, setBillingDay] = useState('1st');
    const pageQuery = useRtoOrderFormData();
    const order = pageQuery.data?.order;
    const paymentMethodResult = useRef<any>(null);
    const sdk = useApiSdk();
    const invalidate = useInvalidateRtoOrderData();

    const appModeData = useAppModeData();
    const rtoFieldsMutation = useSetRtoFields();

    const parentState = {
        paymentMethodId,
        setPaymentMethodId,
        order: order,
        rtoTermsForOrder: pageQuery.data?.rtoTermsForOrder,
        config: pageQuery.data?.rtoConfig,
        paymentMethodResult: paymentMethodResult,
        enableOfflineMethods: true,
        enableAch: true,
    };

    const handleClickContinue = async () => {
        if (!order || !paymentMethodResult.current) {
            return;
        }

        let paymentResult = await sdk.ProcessPayment({
            input: {
                orderId: order.id,
                method: paymentMethodResult.current.code,
                metadata: paymentMethodResult.current,
            },
        });

        if ('message' in paymentResult.processPayment) {
            throw new Error(paymentResult.processPayment.message);
        }

        invalidate(order.id);
    };

    const handleChangeBillingDay = (e: any) => {
        setBillingDay(e.target.value);
        rtoFieldsMutation.mutate({
            orderId: order?.id,
            billingDay: e.target.value,
        });
    };

    useEffect(() => {
        if (order?.rtoContract?.billingDay) {
            setBillingDay(order?.rtoContract?.billingDay);
        }
    }, [order]);

    if (!order?.rtoContract) {
        return null;
    }

    const isPaid = order.payments?.length;
    const enablePaymentDayInput = pageQuery.data?.rtoConfig?.options?.enablePaymentDayInput;
    const billingDays: string[] = pageQuery.data?.rtoConfig?.options?.billingDays || [];
    const daysOfTheMonth: string[] = [];

    for (let i = 1; i <= 31; i++) {
        const day = formatNumberOrdinal(i);
        if (billingDays.length && !billingDays.includes(day)) {
            continue;
        }
        daysOfTheMonth.push(day);
    }

    return (
        <Box>
            <Box
                width="100%"
                alignItems="start"
                borderRadius="md"
                backgroundColor="gray.100"
                px={3}
                py={2}
            >
                <Flex justifyContent="space-between" width="100%" py={2}>
                    <Box>Retail price</Box>
                    <Box>{formatPrice(order.total / 100)}</Box>
                </Flex>
                <Flex justifyContent="space-between" width="100%" py={2}>
                    <Box>Monthly payment</Box>
                    <Box>{formatPrice(order.rtoContract.basePayment)}</Box>
                </Flex>
                <Flex justifyContent="space-between" width="100%" py={2}>
                    <Box>Tax on payment</Box>
                    <Box>{formatPrice(order.rtoContract.tax)}</Box>
                </Flex>
                {order.rtoContract.ldw > 0 && (
                    <Flex justifyContent="space-between" width="100%" py={2}>
                        <Box>Liability damage waiver</Box>
                        <Box>{formatPrice(order.rtoContract.ldw)}</Box>
                    </Flex>
                )}
                <Flex
                    justifyContent="space-between"
                    width="100%"
                    borderTop="1px"
                    borderColor="gray.400"
                    py={2}
                >
                    <Box>Total monthly payment</Box>
                    <Box>{formatPrice(order.rtoContract.recurringPayment)}</Box>
                </Flex>
                {order.rtoContract.securityDeposit > 0 && (
                    <Flex
                        justifyContent="space-between"
                        width="100%"
                        borderTop="1px"
                        borderColor="gray.400"
                        py={2}
                    >
                        <Box>Security deposit</Box>
                        <Box>{formatPrice(order.rtoContract.securityDeposit)}</Box>
                    </Flex>
                )}
                {order.rtoContract.processingFee > 0 && (
                    <Flex
                        justifyContent="space-between"
                        width="100%"
                        py={2}
                    >
                        <Box>Processing fee</Box>
                        <Box>{formatPrice(order.rtoContract.processingFee)}</Box>
                    </Flex>
                )}
                <Flex justifyContent="space-between" width="100%" py={2}>
                    <Box fontWeight="bold" fontSize="lg">
                        {isPaid ? 'Initial payment' : 'Initial payment due'}
                    </Box>
                    <Box fontWeight="bold" fontSize="lg">
                        {formatPrice(order.rtoContract.initialPayment)}
                    </Box>
                </Flex>
            </Box>
            <Box mb={4}>
                {enablePaymentDayInput && (
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        py={4}
                        borderBottom="1px"
                        borderColor="gray.300"
                    >
                        <Box>Next payment due</Box>
                        <Flex alignItems="center">
                            <Select
                                mr={2}
                                size="sm"
                                value={billingDay}
                                onChange={handleChangeBillingDay}
                            >
                                {daysOfTheMonth.map((value) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}
                            </Select>
                            <Box as="span" whiteSpace="nowrap">
                                of the month.
                            </Box>
                        </Flex>
                    </Flex>
                )}
            </Box>
            {!isPaid && <PaymentMethods parentState={parentState} />}
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <ActionBar
                    submitLabel={isPaid ? 'Continue' : 'Submit payment'}
                    onSubmit={!isPaid ? handleClickContinue : undefined}
                    isDisabled={!paymentMethodId && !isPaid}
                />
            </VStack>
        </Box>
    );
};

export default StepPayment;
