import {
    Box,
    Card,
    CardBody,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Switch,
} from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';

export const EmailNotificationSettings = (props: any) => {
    const fieldsControl = useFieldArray({
        control: props.form.control,
        name: 'notification_settings',
    });
    const fields = fieldsControl.fields;
    const borderStyle = {
        borderBottom: '1px',
        borderColor: 'gray.200',
    };

    return (
        <Card bg="white">
            <CardBody>
                <Flex alignItems="center" mb={3}>
                    <Heading size="md">Notifications</Heading>
                </Flex>
                <Box
                    display="table"
                    width="100%"
                >
                    <Box display="table-row">
                        <Box display="table-cell" fontWeight="medium" py={1} {...borderStyle}>Type</Box>
                        <Box display="table-cell" fontWeight="medium" py={1} {...borderStyle}>Recipient</Box>
                        <Box display="table-cell" fontWeight="medium" py={1} width="3rem" {...borderStyle}>
                            Status
                        </Box>
                    </Box>
                    {fields.map((fieldData: any, index) => {
                        const recipientInput = (
                            <Input
                                size="sm"
                                type="text"
                                {...props.form.register(`notification_settings.${index}.recipientEmail`)}
                            />
                        );

                        return (
                            <Box key={fieldData.notification} display="table-row">
                                <Box mb={4} display="table-cell" py={2} {...borderStyle}>
                                    {fieldData.notification}
                                </Box>
                                <Box mb={4} display="table-cell" py={2} {...borderStyle} pr={4}>
                                    {fieldData.recipientType == 'customer' ? 'Customer' : recipientInput}
                                </Box>
                                <Box mb={4} display="table-cell" py={2} {...borderStyle}>
                                    <Switch
                                        isChecked={fieldData.enabled}
                                        onChange={(e) => fieldsControl.update(index,{...fieldData, enabled: e.target.checked}) }
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </CardBody>
        </Card>
    );
};
