import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox,  FormControl, FormErrorMessage, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Stack, StackDivider, Tag, TagCloseButton, TagLabel, useDisclosure, Wrap, WrapItem } from "@pankod/refine-chakra-ui";
import { useList } from "@pankod/refine-core";
import { Editor, useApiSdk, TemplateVariable } from "ui-core";
import { MutableRefObject, useEffect, useState } from "react";
import { IconButton } from '@chakra-ui/react'
import { IconPlus } from "@tabler/icons";

import { ActionMeta, Select as CustomSelect} from "chakra-react-select";
import { ContractTemplate } from "api-client/types";
import { RtoSettingsForm, TargetProductTypes, TemplateUpload } from 'ui-rto';

export interface AccordianProps {
    template?: ContractTemplate

    setIsLoading?: (arg: boolean) => void;
    setTitle(arg: string): void;
    setStates(arg: string): void;
    setCompanies(arg: string): void;
    setCotnent(arg: string): void;
    setVariables(arg: string): void;

    settingsDataRef?: MutableRefObject<any>;
    defaultSettings?: any;
    dataRef?: MutableRefObject<any>;
    contentDefinitionMode?: 'editor' | 'upload';
}

export const TemplateAccordian: React.FC<AccordianProps>  = (props) => {

    const states = [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 
    'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 
    'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 
    'VA', 'WA', 'WV', 'WI', 'WY' ];

    const requiredvars = [
        {"title":"agreement_date","format":"date","type":"customer","color":"green", "required" : false},
        {"title":"customer_name","format":"string","type":"customer","color":"green", "required" : false},
        {"title":"billing_address","format":"string","type":"customer","color":"green", "required" : false},
        {"title":"shipping_address","format":"string","type":"customer","color":"green", "required" : false},
        {"title":"building_details","format":"string","type":"building","color":"blue", "required" : false},
        {"title":"billing_date","format":"date","type":"terms","color":"teal", "required" : false},
        {"title":"initial_payment","format":"currency","type":"terms","color":"teal", "required" : false},
        {"title":"initial_payment_tax","format":"currency","type":"terms","color":"teal", "required" : false},
        {"title":"tax_rate","format":"currency","type":"terms","color":"teal", "required" : false},
        {"title":"ldw_price","format":"currency","type":"terms","color":"teal", "required" : false},
        {"title":"greater_initial_payment","format":"currency","type":"terms","color":"teal", "required" : false},
        {"title":"net_greater_initial_payment","format":"currency","type":"terms","color":"teal", "required" : false},
        {"title":"recurring_payment_tax","format":"currency","type":"terms","color":"teal", "required" : false},
        {"title":"recurring_payment_total","format":"string","type":"terms","color":"teal", "required" : false},
        {"title":"term","format":"string","type":"terms","color":"teal", "required" : false},
        {"title":"epo_percent","format":"number","type":"terms","color":"teal", "required" : false},
        {"title":"retail_price","format":"currency","type":"terms","color":"teal", "required" : false}];
    type option = {
        id: string,
        label: string,
        value: string,
        colorScheme: string,
    }

    const onTitleChange = (event: any) => {
        props.setTitle(event.target.value);
    }

    const [templateVars, setTemplateVarList] = useState(getvarsInitState() as TemplateVariable[]);

    function getvarsInitState() {
        let initState: TemplateVariable[] = [];
        try {
            if (props.template?.variables) {
                initState = JSON.parse(props.template?.variables);
            } else {
                initState = requiredvars;
            }
        } catch(error) {
            console.error(error);
        }
        
        return initState;
    }

    useEffect(() => {
        if (!props.template?.variables) {
            props.setVariables(JSON.stringify(requiredvars));
        }
    }, [props.template?.variables]);

    const saveVariable =(newVar: TemplateVariable) => {
        
        let tempVarList = templateVars;
        tempVarList.forEach( (tempVar) => {
            if(tempVar.title === newVar.title) {
                throw Error("Variable with that title already exists");
            }
        })
        tempVarList.push(newVar);
        saveVariables(tempVarList);
    }

    const editVariable =(newVar: TemplateVariable) => {
        
        let tempVarList: TemplateVariable[] =[];
        templateVars.forEach( (tempVar) => {
            if(tempVar.title === templateVarEditTitle) {
                tempVarList.push(newVar);
            } else {
                tempVarList.push(tempVar);
            }
        })
        saveVariables(tempVarList);
        
    }

    function onTagDeleteClick (title: string) {
        
        let tempVarList: TemplateVariable[] =[];
        templateVars.forEach( (tempVar) => {
            if(tempVar.title === title) {
                //ignore
            } else {
                tempVarList.push(tempVar);
            }
        })
        saveVariables(tempVarList);
        
    }

    function saveVariables(varList: TemplateVariable[]){
        setTemplateVarList(varList);
        if(varList.length == 0) {
            props.setVariables("");
        } else {
            props.setVariables(JSON.stringify(varList));
        }
        clearVarState();
    }

    function clearVarState() {
        setTemplateVarTitle("");
        setTemplateVarType("");
        setTemplateVarFormat("");
        setTemplateVarMessage("");
        setTemplateVarEditTitle("");
        setTemplateVarEdit(false);
    }

    const [templateVarTitle, setTemplateVarTitle] = useState("");
    const [templateVarType, setTemplateVarType] = useState("");
    const [templateVarFromat, setTemplateVarFormat] = useState("");
    const [templateVarMessage, setTemplateVarMessage] = useState("");
    const [templateVarEdit, setTemplateVarEdit] = useState(false);
    const [templateVarEditTitle, setTemplateVarEditTitle] = useState("");

    const onVarTitleChange = (event: any) => {
        setTemplateVarTitle(event.target.value)
        setTemplateVarMessage("Token:{" + event.target.value + "}")
    }

    const onVarTypeChange = (event: any) => {
        setTemplateVarType(event.target.value)
    }

    const onVarFormatChange = (event: any) => {
        setTemplateVarFormat(event.target.value)
    }

    function getInitialStates(){
        let stateVals = new Array(states.length).fill(false);
        
        if(props.template?.states) {
            const selectedStates = props.template?.states.split(",");
            
            if(selectedStates.length >= 50) {
                stateVals = new Array(states.length).fill(true);
            } else {
                for (let x = 0; x < selectedStates.length; x++){
                    
                    for (let y = 0; y < states.length; y++){
                        if(states[y] === selectedStates[x]) {

                            stateVals[y] = true;
                            break;
                        }
                    }
                }
            }
        } 

        return stateVals;
    }

    function getInitialAllState() {
        if(props.template?.states) {
            const selectedStates = props.template?.states.split(",");
            if(selectedStates.length >= 50) {
                return true;
            }
        }
        return false;
    }
    
    const [allChecked, setAllChecked] = useState(getInitialAllState())
    const [checkedItems, setCheckedItems] = useState(getInitialStates())
    

    const handleOnChnageState = (position: any) => {
        let allStateChanged = false;
        let thereIsAFalse = false;
        let updatedCheckedState = new Array(checkedItems.length);
        
        //Loop thrugh the updated state array and manage the state of the "All" checkbox
        for (let x = 0; x < checkedItems.length; x++){
            if(x === position){
                //this is the position that changed so flip it's value
                updatedCheckedState[x] = !checkedItems[x];
            } else {
                //unchanged
                updatedCheckedState[x] = checkedItems[x];
            }
            
            //handle case where all states are manually checked, and update the "All" box
            //if there are now no state boxes unchecked
            if(updatedCheckedState[x] === false) {
                thereIsAFalse = true;
            }
            
            //uncheck 'all' box if a textbox is unchcked
            if(updatedCheckedState[x] === false && allChecked) {
                allStateChanged = true;
            }
        }

        setCheckedItems(updatedCheckedState);
        saveStateSelectionsAsString(updatedCheckedState);

        if(allStateChanged){
            setAllChecked(false);
        } else if(thereIsAFalse === false) {
            setAllChecked(true);
        }
    }

    const handleOnChnageAll = () => {
        setAllChecked( !allChecked);
        const updatedCheckedState = checkedItems.map((item) =>
            item = !allChecked
        );

        setCheckedItems(updatedCheckedState);
        saveStateSelectionsAsString(updatedCheckedState);
    }

    function saveStateSelectionsAsString(stateSelections: boolean[]){
        let stateString = "";
        for (let x = 0; x < stateSelections.length; x++){
            if(stateSelections[x]) {
                if(stateString.length > 0 ){
                    stateString += ","
                }
                stateString += states[x]; 
            }
        }
        props.setStates(stateString);

    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    const templateContentChange = (arg: string) => {
        props.setCotnent(arg);        
    }

    const onEditorCopyTemplate = (variables: string) => {
        console.log("editor copy click: ");
        
        let copiedVars: TemplateVariable[] = JSON.parse( variables);
        setTemplateVarList(copiedVars);
        props.setVariables(JSON.stringify(copiedVars));
    }
    
    const sdk = useApiSdk();

    async function getContractTemplate(id: string) {
        try {
            const result = await sdk.GetContractTemplate({id }); 
            let copiedVars: TemplateVariable[] = JSON.parse( result.contractTemplate?.variables as string);
            setTemplateVarList(copiedVars);
            props.setVariables(JSON.stringify(copiedVars));
            props.setCotnent(result.contractTemplate?.content as string);
                    
        } catch(error) {
            console.error(error);
        }
    }

    
    const templateListResult = useList({
        resource: "contractTemplates",
        config: {
            sort: [{ order: "asc", field: "title" }],
            pagination: { current: 0, pageSize: 200 },
        },

    });

    const companyListResult = useList({
        resource: "organization",
        config: {
            sort: [{ order: "asc", field: "name" }],
            pagination: { current: 0, pageSize: 200 },
        },

    });

    const companyListData = companyListResult?.data?.data;

    const templateListData = templateListResult?.data?.data as ContractTemplate[];
    
    let companySelectOptions: option[] = [{
        label: "All",
        value: "All",
        colorScheme: "blue",
        id: "all",
    }];
    
    companyListData?.map((company) =>   
        {
            let newOption = { 
                id: company.id as string,
                label: company.name,
                value: company.name,
                colorScheme: 'green',
            }
            companySelectOptions.push(newOption);
        });
    
    
    const [companyList, setCompanyList] = useState(getInitialCompanyState());
    
    function getInitialCompanyState() {
        let tempCompanyList: option[] = [];
        if(props.template?.companies) {
            const companyIds = props.template?.companies.split(",");
            for (let x = 0; x < companyIds.length; x++){
                companySelectOptions.forEach( val =>  {
                    if(val.id === companyIds[x]) {
                        tempCompanyList.push(val);
                    }
                });
            }
        }
        
        return tempCompanyList;
    }

    const onCompanySelectChange = (options: readonly option[], actionMeta: ActionMeta<option>) => {
        //in order to avoid UI confusion, if 'All' is selected remove other selections
        //as they don't matter
        let tempCompanyList: option[] = [];
        let allIsSelected = false;
        let companyIdList = "";
        options.forEach( val =>  {
            if(val.label === "All") {
                tempCompanyList= [];
                tempCompanyList.push(val);
                allIsSelected= true;
                companyIdList = "all";
            } else if (allIsSelected === false) {
                tempCompanyList.push(val)
                if(companyIdList.length > 0) {
                    companyIdList += ",";
                }
                companyIdList += val.id;
            }
        });

        setCompanyList(tempCompanyList);
        props.setCompanies(companyIdList);
    }

    function getVariableTag(variable: TemplateVariable) {
        if(variable.required && variable.required === true) {
            
            return(
                <WrapItem key={"w"+variable.title}>
                    <Tag
                        size="md"
                        key={variable.title}
                        borderRadius='full'
                        variant='solid'
                        colorScheme={variable.color}
                        
                        >
                        <TagLabel>{variable.title}</TagLabel>
                        <TagCloseButton onClick={() => {
                            onTagDeleteClick(variable.title)
                        }}/>
                    </Tag>
                 </WrapItem>
            );
        }else {
            
            return(
            <WrapItem key={"w"+variable.title}>
                    <Tag
                        size="md"
                        key={variable.title}
                        borderRadius='full'
                        variant='solid'
                        colorScheme={variable.color}
                        
                        >
                        <TagLabel onClick={ () => {
                            setTemplateVarTitle(variable.title);
                            setTemplateVarType(variable.type);
                            setTemplateVarFormat(variable.format);
                            setTemplateVarEdit(true);
                            setTemplateVarEditTitle(variable.title);
                            onOpen()}}

                            >{variable.title}</TagLabel>
                        <TagCloseButton onClick={() => {
                            onTagDeleteClick(variable.title)
                        }}/>
                    </Tag>
                 </WrapItem>);
        }
    }

    
return (
    <Box>
            <Accordion defaultIndex={[0]} allowToggle>
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left' color="green" fontWeight="bold">
                        Template Title
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <FormControl isRequired>
                            <Input 
                                id="title"
                                placeholder="Title" 
                                defaultValue={props.template?.title ? props.template?.title : ""}
                                onChange={onTitleChange}
                            />
                            <FormErrorMessage></FormErrorMessage>
                        </FormControl>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left' color="green" fontWeight="bold">
                        Target States
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <Stack divider={<StackDivider />} spacing='4'>
                            <Box>
                                <FormControl>
                                    <Checkbox
                                        isChecked={allChecked}
                                        onChange={() => handleOnChnageAll()}
                                        id="all"
                                        name="all"
                                    >
                                        All
                                    </Checkbox>
                                    <SimpleGrid columns={12} spacing={5} >
                                        {states?.map((state, index) =>   
                                                <Checkbox
                                                    name={state}
                                                    id={state}
                                                    value={state}
                                                    key={state}
                                                    isChecked={checkedItems[index]}
                                                    onChange={() => handleOnChnageState(index)}
                                                >
                                        {state}
                                        </Checkbox>
                                            )}
                                        
                                    </SimpleGrid>
                                </FormControl>
                            </Box>
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left' color="green" fontWeight="bold">
                        Target Companies
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={40}>
                        <Box>
                            <FormControl>
                                <CustomSelect 
                                    key="companyselection"
                                    isMulti={true}
                                    value={companyList}
                                    id='company' 
                                    onChange={onCompanySelectChange}
                                    placeholder='Select Target Companies' 
                                    options={companySelectOptions}
                                    defaultValue={companyList}
                                />
                            </FormControl>
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
                <TargetProductTypes settingsRef={props.settingsDataRef} initialState={props.template?.productTypes || []} />
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left' color="green" fontWeight="bold">
                        Contract Content
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {props.contentDefinitionMode == 'upload' && (
                            <TemplateUpload
                                dataRef={props.dataRef}
                                setIsLoading={props.setIsLoading}
                                template={props.template}
                            />
                        )}
                        {props.contentDefinitionMode == 'editor' && (
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Editor 
                                        contentChange={templateContentChange}
                                        content={props.template?.content ? props.template.content : undefined}
                                        onCopyClick={onEditorCopyTemplate}
                                        templateList={templateListData}
                                    />
                                </Box>
                                <Box>
                                    <HStack spacing={4}>
                                        <Wrap>
                                        { 
                                            templateVars?.map((templateVars) =>
                                                getVariableTag(templateVars)
                                            )}
                                        </Wrap>
                                        <IconButton ml="5" colorScheme="green" aria-label="Add" icon={<IconPlus/>} onClick={onOpen}></IconButton>                                   
                                    </HStack>
                                </Box>
                            </Stack>
                        )}
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' color="green" fontWeight="bold">
                                Contract Settings
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel>
                        <RtoSettingsForm dataRef={props.settingsDataRef} initialState={props.template?.settings} defaultSettings={props.defaultSettings} />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>

            <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Define Variable</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        
                        <FormControl>
                            <FormLabel>Type</FormLabel>
                            <Select id='type' placeholder='Select Variable Type' defaultValue={templateVarType} onChange={onVarTypeChange}>
                                <option value='customer'>Customer</option>
                                <option value='building'>Building</option>
                                <option value='terms'>Terms</option>
                                <option value='signature'>Signature</option>
                            </Select>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Formatting</FormLabel>
                            <Select id='fromat' placeholder='Select Variable Format' defaultValue={templateVarFromat} onChange={onVarFormatChange}>
                                <option value='string'>String</option>
                                <option value='currency'>Currnecy</option>
                                <option value='number'>Number</option>
                                <option value='date'>Date</option>
                                <option value='signature'>Signature</option>
                            </Select>
                        </FormControl>
                        
                        <FormControl mt={4}>
                            <FormLabel>Title</FormLabel>
                            <Input 
                                id="varTitle"  
                                defaultValue={templateVarTitle}
                                onChange={onVarTitleChange}/>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel color="green">{templateVarMessage}</FormLabel>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={ () => {
                            try{
                                if(templateVarTitle === "" ) {
                                    throw Error("Title is required")
                                }
                                if(templateVarFromat === "" ) {
                                    throw Error("You must select a format")
                                }
                                if(templateVarType === "" ) {
                                    throw Error("You must select a type")
                                }
                                
                                let color = "green"
                                if(templateVarType === "building") {
                                    color = "blue"
                                } else if(templateVarType === "terms" ) {
                                    color = "teal"
                                } else if(templateVarType === "signature" ) {
                                    color = "red"
                                }
                                const newvar: TemplateVariable = {
                                title: templateVarTitle,
                                format: templateVarFromat,
                                type: templateVarType,
                                color: color
                            }
                            
                                if(templateVarEdit) {
                                    editVariable(newvar)
                                } else {
                                    saveVariable(newvar);
                                }
                                onClose();
                            } catch(error) {
                                if(error instanceof Error){
                                    setTemplateVarMessage(error.message);
                                }
                            }
                            
                        }} 
                        >
                        Save
                        </Button>
                        <Button onClick={ () => {
                            clearVarState();
                            onClose() }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
);
};