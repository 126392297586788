import { ORDER_DETAIL_FRAGMENT, FULFILLMENT_FRAGMENT } from './order';
import { ERROR_RESULT_FRAGMENT } from './shared';

export const SET_RTO_APP_FIELDS = /* GraphQL */ `
mutation SetRtoAppFields($input: RtoAppFieldsInput!) {
    setRtoAppFields(input: $input) {
        ...OrderDetail
    }
    ${ORDER_DETAIL_FRAGMENT}
}
`;

export const SET_RTO_FIELDS = /* GraphQL */ `
mutation SetRtoFields($input: RtoFieldsInput!) {
    setRtoFields(input: $input) {
        ...OrderDetail
    }
    ${ORDER_DETAIL_FRAGMENT}
}
`;

export const SET_RTO_ITEM = /* GraphQL */ `
mutation SetRtoItem($input: RtoItemInput!) {
    setRtoItem(input: $input) {
        ...OrderDetail
    }
    ${ORDER_DETAIL_FRAGMENT}
}
`;

export const SET_RTO_CUSTOMER = /* GraphQL */ `
mutation SetRtoCustomer($input: RtoCustomerInput!) {
    setRtoCustomer(input: $input) {
        ...OrderDetail
    }
    ${ORDER_DETAIL_FRAGMENT}
}
`;

export const GET_RTO_PAYMENT_ESTIMATE = /* GraphQL */ `
    query GetRtoPaymentEstimate($options: RtoPaymentEstimateOptions!) {
        rtoPaymentEstimate(options: $options) {
            term
            basePayment
            initialPayment
            recurringPayment
            ldwPrice
            ldw
            tax
            taxRate
            requiredPaymentsDown
            greaterPaymentOptions
            processingFee
        }
    }
`;

export const RTO_ORDER_FORM_DATA = /* GraphQL */ `
    query GetRtoOrderFormData($orderId: ID!) {
        order(id: $orderId) {
            ...OrderDetail
        }
        rtoTermsForOrder(orderId: $orderId) {
            term
            originalInitialPayment
            originalBasePayment
            originalRecurringPayment
            originalTax
            basePayment
            initialPayment
            netInitialPayment
            recurringPayment
            ldwPrice
            ldw
            tax
            taxRate
            requiredPaymentsDown
            greaterPaymentOptions
            enableSecurityDeposit
            processingFee
        }
        rtoConfig
        ${ORDER_DETAIL_FRAGMENT}
    }
`;

export const FINALIZE_CONTRACT = /* GraphQL */ `
mutation FinalizeContract($input: FinalizeContractInput!) {
    finalizeContract(input: $input) {
        redirectUrl
    }
}
`;

export const GET_RTO_CONFIG = /* GraphQL */ `
query GetRtoConfig {
    rtoConfig
}
`;

export const PROCESS_PAYMENT = /* GraphQL */`
    mutation ProcessPayment($input: ProcessPaymentInput!) {
        processPayment(input: $input) {
            ...OrderDetail
            ...ErrorResult
        }
    }
    ${ORDER_DETAIL_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const UPLOAD_ATTACHMENT = /* GraphQL */`
    mutation UploadAttachment($orderId: String!, $type: String $file: Upload!) {
        uploadAttachment(orderId: $orderId, type: $type, file: $file) {
            ...OrderDetail
        }
    }
    ${ORDER_DETAIL_FRAGMENT}
`;

export const DELETE_ATTACHMENT = /* GraphQL */ `
    mutation DeleteAttachment($input: DeleteAttachmentInput!) {
        deleteAttachment(input: $input) {
            result
            message
        }
    }
`;

export const UPDATE_DOCUMENT_METADATA = /* GraphQL */`
    mutation updateDocumentMetaData($input: UpdateDocumentMetaDataInput!) {
        updateDocumentMetaData(input: $input) {
            rtoContract
        }
    }
`;

export const RECORD_DELIVERY = /* GraphQL */`
    mutation RecordDelivery($input: RecordDeliveryInput!) {
        recordDelivery(input: $input) {
            ...Fulfillment
            ... on CreateFulfillmentError {
                errorCode
                message
                fulfillmentHandlerError
            }
            ... on FulfillmentStateTransitionError {
                errorCode
                message
                transitionError
            }
            ...ErrorResult
        }
    }
    ${FULFILLMENT_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const GRANT_DOCUMENT_ACCESS = /* GraphQL */`
    mutation GrantDocumentAccess($orderId: String!, $docType: String!) {
        grantDocumentAccess(orderId: $orderId, docType: $docType) {
            token
        }
    }
`;
