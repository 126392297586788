import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import { mergeRegister } from '@lexical/utils';
import { useCallback, useEffect, useState } from "react";
import {
  IconBold,
  IconStrikethrough,
  IconItalic,
  IconUnderline,
  IconAlignLeft,
  IconAlignRight,
  IconAlignCenter,
  IconAlignJustified,
  IconArrowBack,
  IconArrowForward,
  IconIndentIncrease,
  IconIndentDecrease
} from '@tabler/icons';
import { Button, Box, HStack, Select } from "@pankod/refine-chakra-ui";
import {
    $getRoot,
    $getSelection,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    FORMAT_ELEMENT_COMMAND,
    UNDO_COMMAND,
    REDO_COMMAND,
    CLEAR_HISTORY_COMMAND,
    INDENT_CONTENT_COMMAND,
    OUTDENT_CONTENT_COMMAND
  } from 'lexical';
import { ContractTemplate } from "api-client/types";
import { useApiSdk } from "ui-core";



  export interface EditorProps {
    content?: string,
    contentChange(arg: string): void;
    onCopyClick(arg: string): void;
    templateList?: ContractTemplate[];
  }

function Placeholder() {
    return <div style={editorPlaceholder}>Enter some contract language...</div>;
  }

const lexicalTheme = {
    ltr: "ltr",
    rtl: "rtl",
    placeholder: "editor-placeholder",
    paragraph: "editor-paragraph",
    quote: "editor-quote",
    heading: {
      h1: "editor-heading-h1",
      h2: "editor-heading-h2",
      h3: "editor-heading-h3",
      h4: "editor-heading-h4",
      h5: "editor-heading-h5"
    },
    list: {
      nested: {
        listitem: "editor-nested-listitem"
      },
      ol: "editor-list-ol",
      ul: "editor-list-ul",
      listitem: "editor-listitem"
    },
    image: "editor-image",
    link: "editor-link",
    text: {
      bold: "editor-text-bold",
      italic: "editor-text-italic",
      overflowed: "editor-text-overflowed",
      hashtag: "editor-text-hashtag",
      underline: "editor-text-underline",
      strikethrough: "editor-text-strikethrough",
      underlineStrikethrough: "editor-text-underlineStrikethrough",
      code: "editor-text-code"
    },
    code: "editor-code",
    codeHighlight: {
      atrule: "editor-tokenAttr",
      attr: "editor-tokenAttr",
      boolean: "editor-tokenProperty",
      builtin: "editor-tokenSelector",
      cdata: "editor-tokenComment",
      char: "editor-tokenSelector",
      class: "editor-tokenFunction",
      "class-name": "editor-tokenFunction",
      comment: "editor-tokenComment",
      constant: "editor-tokenProperty",
      deleted: "editor-tokenProperty",
      doctype: "editor-tokenComment",
      entity: "editor-tokenOperator",
      function: "editor-tokenFunction",
      important: "editor-tokenVariable",
      inserted: "editor-tokenSelector",
      keyword: "editor-tokenAttr",
      namespace: "editor-tokenVariable",
      number: "editor-tokenProperty",
      operator: "editor-tokenOperator",
      prolog: "editor-tokenComment",
      property: "editor-tokenProperty",
      punctuation: "editor-tokenPunctuation",
      regex: "editor-tokenVariable",
      selector: "editor-tokenSelector",
      string: "editor-tokenSelector",
      symbol: "editor-tokenProperty",
      tag: "editor-tokenProperty",
      url: "editor-tokenOperator",
      variable: "editor-tokenVariable"
    }
  };

    const editorContainer: any = {
        margin: "20px auto 20px auto",
        maxWidth: "600px",
        borderRadius: "2px",
        color: "#000",
        position: "relative",
        lineHeight: "20px",
        textAlign: "left",
    }

    const editorInner: any = {
        background: "#fff",
        position: "relative"
    }
    const editorInput: any = {
        minHeight: "150p",
        resize: "none",
        fontSize: "15px",
        position: "relative",
        tabSize: "1",
        outline: "0",
        padding: "15px 10px",
        caretColor: "#444",
        borderColor: "#000",
    }
    const editorPlaceholder: any = {
        color: "#999",
        overflow: "hidden",
        position: "absolute",
        textOverflow: "ellipsis",
        top: "0px",
        left: "10px",
        fontSize: "15px",
        userSelect: "none",
        display: "inline-block",
        pointerEvents: "none",
    }

    const toolbarWrapper = {
        display: "flex", 
        //position: "fixed", 
        bottom: "2rem", 
        left: "50%", 
        zIndex: "20", 
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem", 
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem", 
        marginBottom: "1rem", 
        marginLeft: "0.5rem", 

        alignItems: "center", 
        height: "2.5rem", 
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)", 
        background: "#1b2733",
    }

    const toolbarButtonWrapper = {
        transitionProperty: "background-color, border-color, color, fill, stroke",
        transitionDuration: "100ms",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 1, 1)",
        '&:hover': {
            backgroundColor: '#777',
          }
    }
    const toolebarSeperator = {
        display: "block",
        backgroundColor: "#6B7280",
        height: "100%",
        width: "1px",
    }

    const toolbarButton = {
        color: "#ffffff",
        width: "1.3rem",
        height: "1.3rem",
    }

    function cleanContent(content: string) {
      if(!content || content.length < 1 ) {
        return null;
      }
      
      return content.replace(/\n/g,'');
    }


  
  export const Editor: React.FC<EditorProps> =(props) => {
    function onChange(state: any) {
      
      try {
        state.read(() => {
          const root = $getRoot();
          const selection = $getSelection();
          props.contentChange(JSON.stringify(state));
          
          
        });
    }catch(Error) {
      console.error(Error);
    }
    }

    const editorConfig = {
      // The editor theme
      theme: lexicalTheme,
      editorState: cleanContent(props.content as string),
      namespace: "templateEditor",
      // Handling of errors during update
      onError(error: any) {
        console.error(error);
      },
      // Any custom nodes go here
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode
      ]
    };
    const [copyTemplateSelection, setCopyTemplateSelection] = useState("");

    const onCopySelectChange = (event: any) => {
      setCopyTemplateSelection(event.target.value)
    }

    const sdk = useApiSdk();

    async function getContractTemplate(id: string, editor: any) {
      
      try {
            const result = await sdk.GetContractTemplate({id }); 
            let content = result.contractTemplate?.content as string;
            const editorState = editor.parseEditorState(content);
            editor.setEditorState(editorState);
            editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
            props.contentChange(content);
            props.onCopyClick(result.contractTemplate?.variables as string);
                    
        } catch(error) {
            console.error(error);
        }
    }

    const CopyPlugin = () => {
      const [editor] = useLexicalComposerContext();
      const onButtonClick = () => {
          
          try{ 
              getContractTemplate(copyTemplateSelection, editor);
          } catch(error: any) {
              console.error(error);
          }
      };
  
      return ( 
          <Box>
              <HStack pb={2}>
                {"Length:" + props.templateList?.length}
                  <Select placeholder='Select Existing Contract' size="sm" defaultValue={copyTemplateSelection} onChange={onCopySelectChange}>
                      { props.templateList?.map((template) => 
                          <option key={template.id} value={template.id}>{template.title}</option>
                        )
                      }
                  </Select>
                  <Button colorScheme={"orange"} onClick={onButtonClick}>Copy</Button>
              </HStack>
          </Box>
      
      );
  };

    return (
      <LexicalComposer initialConfig={editorConfig}>
        <div style={editorContainer}>
          
          <div style={editorInner}>
            <RichTextPlugin
              contentEditable={<ContentEditable style={editorInner} />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <Toolbar/>
            <OnChangePlugin onChange={onChange} />
            <CopyPlugin/>
          </div>
        </div>
      </LexicalComposer>
    );
  }



//toolbar influenced by:
//https://stackblitz.com/edit/react-4rxemf?file=src%2FEditor.js

const Toolbar = () => {
    //TS was having an issue with undo & redo function sigs, so pass a empy var
    let voidVar: void;
    const [editor] = useLexicalComposerContext();
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
  
    const updateToolbar = useCallback(() => {
      const selection = $getSelection() as any;
  
      if ($isRangeSelection(selection)) {
        setIsBold(selection.hasFormat('bold'));
        setIsItalic(selection.hasFormat('italic'));
        setIsStrikethrough(selection.hasFormat('strikethrough'));
        setIsUnderline(selection.hasFormat('underline'));
      }
    }, [editor]);
  
    useEffect(() => {
      return mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        })
      );
    }, [updateToolbar, editor]);

    return (
      <div style={toolbarWrapper}>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
        >
          <IconBold style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}
        >
            <IconStrikethrough style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
        >
            <IconItalic style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
        >
            <IconUnderline style={toolbarButton}/>
        </button>
  
        <span style={toolebarSeperator}></span>
  
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
            }}
        >
          <IconAlignLeft style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
            }}
        >
            <IconAlignCenter style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
            }}
        >
            <IconAlignRight style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
            }}
        >
            <IconAlignJustified style={toolbarButton}/>
        </button>

        <span style={toolebarSeperator}></span>

        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
            }}
        >
          <IconIndentIncrease style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
            }}
        >
          <IconIndentDecrease style={toolbarButton}/>
        </button>
  
        <span style={toolebarSeperator}></span>
  
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(UNDO_COMMAND, voidVar);
            }}
        >
            <IconArrowBack style={toolbarButton}/>
        </button>
        <button
            style={toolbarButtonWrapper}
            onClick={() => {
                editor.dispatchCommand(REDO_COMMAND, voidVar);
            }}
        >
            <IconArrowForward style={toolbarButton}/>
        </button>
        </div>
    );
};




