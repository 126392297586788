import { useMemo, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Stack,
    useDisclosure,
} from '@chakra-ui/react';
import { useInvalidate, useNotification } from '@pankod/refine-core';
import { useApiSdk } from 'ui-core';

/**
 * Create a date object from a date string in format YYYY-MM-DD
 * using local timezone.
 * @param dateString Date string in format YYYY-MM-DD
 * @returns Date
 */
function parseDate(dateString: string) {
    const dateParts = dateString.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Month is 0-based
    const day = parseInt(dateParts[2]); 

    return new Date(year, month, day);
}

export const DeliveryModal = (props: { orderId: string; }) => {
    const today = useMemo(
        () =>
            new Intl.DateTimeFormat('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).format(new Date()),
        []
    );

    const [deliveryDate, setDeliveryDate] = useState(today);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const notification = useNotification();
    const invalidate = useInvalidate();
    const sdk = useApiSdk();

    const onSubmit = async () => {
        setIsSubmitting(true);

        try {
            const result = await sdk.RecordDelivery({
                input: {
                    orderId: props.orderId,
                    deliveryDate: parseDate(deliveryDate).toISOString(),
                }
            });
            if ('message' in result.recordDelivery) {
                throw new Error(result.recordDelivery.message);
            }
            invalidate({
                resource: 'orders',
                id: props.orderId,
                invalidates: ['detail', 'list'],
            });
        } catch (e) {
            let message = e instanceof Error ? e.message : 'Something went wrong';
            if (message.includes(': {')) {
                message = message.split(': {')[0];
            }
            if (notification.open) {
                notification.open({
                    type: 'error',
                    message: 'Error',
                    description: message,
                });
            }
        } finally {
            // set isSubmitting to false after a delay to show indicator while "invalidate" request is loading
            setTimeout(() => {
                setIsSubmitting(false);
                onClose();
            }, 300);
        }
    };

    return (
        <>
            <Button onClick={onOpen} variant="outline">Record Delivery</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Record Delivery</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Delivery Date</FormLabel>
                                <Input
                                    type="date"
                                    value={deliveryDate}
                                    onChange={(e) => setDeliveryDate(e.target.value)}
                                />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            variant="outline"
                            onClick={onSubmit}
                            isLoading={isSubmitting}
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeliveryModal;
